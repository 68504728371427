export default function updateCollapsiblesOverflow(tab: String): void {
  const collapsibleElements = Array.from(
    document.getElementsByClassName('Collapsible')
  );

  collapsibleElements.forEach(element => {
    const content = element.querySelector(
      '.Collapsible__contentOuter'
    ) as HTMLElement;
    const isVisible = Array.from(element.classList).some(cls =>
      cls.startsWith(`filters-${tab}`)
    );

    content.style.overflow = isVisible ? 'visible' : 'hidden';
  });
}
