import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import ReactModal from 'react-modal';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdClose, MdStar, MdStarBorder } from 'react-icons/md';

import { MdAdd } from 'react-icons/md';

import { format, parseJSON } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import Collapsible from 'react-collapsible';
import { FiCornerDownLeft } from 'react-icons/fi';
import Trigger from '../../pages/Playground/components/Trigger';
import { Container as DateContainer } from '../../components/DatePicker/styles';
import Select from '../../components/Select';
import Button from '../../components/Button';
import { Option } from '../../components/CheckboxInput';

import { useWizard } from '../../hooks/wizard';

import { useAuth } from '../../hooks/auth';

import { filtersOptions } from '../../shared/constants';
import { ListItemFormData } from '../../shared/interfaces';

import {
  ContainerAddModal,
  ModalHeader,
  ModalContent,
  ButtonContainer,
  CollapsibleWrapper,
  ExpandableContent,
  IndividualItem,
  ItemPrice,
} from './styles';
import api from '../../services/api';
import Warnings from './components/Warnings';
import QuoteOption from '../../components/QuoteOption';
import formatDate from '../../utils/formateDate';
import formatPrice from '../../utils/formatPrice';
import RadioInput from './components/RadioInputAllyPlus';
import allyPlusImage from '../../assets/ally_plus_main.png';
import allyPlusLogo from '../../assets/logo_ally_plus.png';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import { Duration } from '../../components/ListItem/styles';
import { useStateCallback } from '../../hooks/useStateCallback';
import mockDataForFetch from '../../pages/Playground/data/mockDataForFetch.json';
import updateCollapsiblesOverflow from '../../utils/updateCollapsiblesOverflow';
import Loading from '../../components/Loading';

interface ModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isAddOptionLoading?: boolean;
  fiveOptions: boolean;
  programStartDate: Date;
  defaultCheckedOptions: boolean;
  data: ListItemFormData;
  feeData: ListItemFormData;
  setDefaultCheckedOptions: (data: boolean) => void;
  handleCloseModal: () => void;
  handleAddItemToQuoteSubmit: (data: any) => void;
  handleAddOption: () => void;
  courseEndDate: Date;
  calculateEndDate: (
    itemType: string,
    startDate: string | Date,
    duration: number,
    durationType: string
  ) => Date | null;
  handleDurationChange: (
    data: ListItemFormData,
    isSuggestive: boolean
  ) => Promise<void>;
  resultItems: any;
  accommodationItems: string[];
  insuranceItems: string[];
  addonsItems: string[];
  fetchDataForModal: (
    currentResultItems: any,
    modalData: any,
    modalTab: string
  ) => void;
  searchLoading: boolean;
  handleAddItemToQuote: (data: ListItemFormData, quote: any) => void;
  handleFinishAndSaveQuotes: () => Promise<void>;
  submittableField: string[];
  setSubmittableField: (type: string[]) => void;
  editingItemSuggestiveFees: string[];
  handleAddSuggestiveFeeToQuote: (arg: any) => void;
  handleRemoveItemFromQuote: (
    index: number,
    type: string | undefined,
    itemId: number
  ) => Promise<void>;
  radioValue: string;
}

ReactModal.setAppElement('#root');

const AddItemToQuoteModal: React.FC<ModalProps> = ({
  data,
  feeData,
  programStartDate,
  isOpen,
  isLoading,
  isAddOptionLoading,
  fiveOptions,
  defaultCheckedOptions,
  setDefaultCheckedOptions,
  handleCloseModal,
  handleAddItemToQuoteSubmit,
  handleAddOption,
  courseEndDate,
  calculateEndDate,
  handleDurationChange,
  resultItems,
  accommodationItems,
  insuranceItems,
  addonsItems,
  fetchDataForModal,
  searchLoading,
  handleAddItemToQuote,
  handleFinishAndSaveQuotes,
  submittableField,
  setSubmittableField,
  editingItemSuggestiveFees,
  handleAddSuggestiveFeeToQuote,
  handleRemoveItemFromQuote,
  radioValue,
}) => {
  const [warnings, setWarnings] = useState([]);
  const [isWarned, setIsWarned] = useState(false);
  const [futureCourse, setFutureCourse] = useState(false);
  const [futurePrice, setFuturePrice] = useState(false);
  const [newProgramStartDate, setNewProgramStartDate] = useState<Date | null>();
  const [newEndDate, setNewEndDate] = useState<Date | null>(null);
  const [newDuration, setNewDuration] = useState<number | null>(null);
  const [dateInfo, setDateInfo] = useState(false);
  const [addJSON, setAddJSON] = useState<boolean>(false);
  const [
    isAccommodationFiltersOpen,
    setIsAccommodationFiltersOpen,
  ] = useState<boolean>(false);
  const [isInsuranceFiltersOpen, setIsInsuranceFiltersOpen] = useState<boolean>(
    false
  );
  const [isAddOnFiltersOpen, setIsAddOnFiltersOpen] = useState<boolean>(false);
  const [currentFiltersTab, setCurrentFiltersTab] = useState<string>(
    filtersOptions.FILTERS_COURSES
  );
  const [isCourseFiltersOpen, setIsCourseFiltersOpen] = useState<boolean>(true);
  const [feeItemAsJSON, setFeeItemAsJSON] = useState<ListItemFormData | null>(
    null
  );
  const [
    wasAccommodationInserted,
    setWasAccommodationInserted,
  ] = useState<boolean>(false);
  const [wasInsuranceInserted, setWasInsuranceInserted] = useState<boolean>(
    false
  );
  const [selectedAccommodationsId, setSelectedAccommodationsId] = useState<
    Number[]
  >([]);
  const [selectedInsurancesId, setSelectedInsurancesId] = useState<Number[]>(
    []
  );
  const [selectedAddonsId, setSelectedAddonsId] = useState<Number[]>([]);
  const [selectedSuggestiveFeeId, setSelectedSuggestiveFeeId] = useState<
    Number[]
  >([]);

  const warningSearch = useRef(0);
  const modalFormRef = useRef<FormHandles>(null);
  const newData = useRef<ListItemFormData | null>();
  const { addToast } = useToast();

  const sendValue = (value: '0' | '1') => {
    // setRadioValue(Number(value));
    setDefaultCheckedOptions(false);
  };

  const { quotesData } = useWizard();

  let itemAsJSON: ListItemFormData | null = null;

  if (newData.current) {
    itemAsJSON = newData.current;
  }
  if (data.data && data.type === 'Courses') {
    try {
      itemAsJSON = JSON.parse(data.data);
    } catch (error) {
      console.error('Erro ao parsear JSON:', error);
    }
  }

  function handleData(originalData, newDataCurrent) {
    if (newDataCurrent) {
      return newDataCurrent;
    }
    if (originalData) {
      try {
        return JSON.parse(originalData);
      } catch (error) {
        console.error(`Error parsing JSON for ${data.type}:`, error);
        return null;
      }
    }
    return null;
  }

  if (data.type === filtersOptions.FILTERS_COURSES) {
    itemAsJSON = handleData(data.data, newData.current);
  } else if (
    data.type === filtersOptions.FILTERS_ACCOMMODATIONS ||
    data.type === filtersOptions.FILTERS_ADDONS ||
    data.type === filtersOptions.FILTERS_INSURANCES
  ) {
    setFeeItemAsJSON(handleData(data.data, newData.current));
  }

  // useEffect(() => {
  //   if (data.type === filtersOptions.FILTERS_COURSES) {
  //     itemAsJSON = handleData(data.data, newData.current);
  //   } else if (
  //     data.type === filtersOptions.FILTERS_ACCOMMODATIONS ||
  //     data.type === filtersOptions.FILTERS_ADDONS ||
  //     data.type === filtersOptions.FILTERS_INSURANCES
  //   ) {
  //     setFeeItemAsJSON(handleData(data.data, newData.current));
  //   }
  // }, [data, handleData, itemAsJSON, newData]);

  const { user } = useAuth();

  const userType = useMemo(() => {
    const isAccountAllyPlus = user.office.account.allyPlus === 1;
    const isOfficeAllyPlus = Boolean(user.office.plugAndPlay);
    if (isAccountAllyPlus) {
      return 'allyplus';
    }
    if (!isAccountAllyPlus && isOfficeAllyPlus) {
      return 'hybrid';
    }
    return 'backoffice';
  }, [user]);

  const isIEAccount = user.account_id === 2015;

  let wait_timer: any;
  wait_timer = null;
  const WAIT_INTERVAL = 1000;

  const isValueExpired: boolean = useMemo(() => {
    if (!itemAsJSON) return '';
    const { type } = data;

    return type === 'Courses'
      ? itemAsJSON.coursecampus.priceIsExpired &&
          !itemAsJSON.coursecampus.priceIsAboutToExpire &&
          !itemAsJSON.coursecampus.srIsAboutToExpire
      : itemAsJSON.priceIsExpired &&
          !itemAsJSON.priceIsAboutToExpire &&
          !itemAsJSON.srIsAboutToExpire;
  }, [itemAsJSON, data]);
  // const [radioValue, setRadioValue] = useState(!isValueExpired ? 1 : 0);

  const isItemAllyPlus = itemAsJSON ? Boolean(itemAsJSON.isAllyPlus) : '';
  const isItemBackoffice = itemAsJSON ? Boolean(itemAsJSON.isBackoffice) : '';
  const initialRadioValue =
    isItemAllyPlus && userType !== 'backoffice' && !isValueExpired ? 1 : 0;

  const itemsRadioInput = useMemo(() => {
    const sellDirectly = [{ id: 0, label: 'Sell directly' }];
    const sellWithAllyPlus = [{ id: 1, label: 'Sell with', allyPlus: true }];
    const sellWithAny = [
      { id: 1, label: 'Sell with', allyPlus: true },
      { id: 0, label: 'Sell directly' },
    ];

    if (isIEAccount) {
      return sellDirectly;
    }
    if (!itemAsJSON) {
      return sellWithAny;
    }
    if (userType !== 'allyplus') {
      if (isItemAllyPlus) {
        return isItemBackoffice ? sellWithAny : sellWithAllyPlus;
      }
      return sellDirectly;
    }
    return sellWithAllyPlus;
  }, [userType, isItemAllyPlus, isItemBackoffice, itemAsJSON, isIEAccount]);

  const itemType = useMemo(() => {
    const { type } = data;
    if (!itemAsJSON || !type) {
      return '';
    }

    if (type === filtersOptions.FILTERS_COURSES) {
      return 'courses';
    }
    if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
      return 'accommodations';
    }
    if (
      type === filtersOptions.FILTERS_INSURANCES ||
      type === filtersOptions.FILTERS_ADDONS ||
      type === filtersOptions.FILTERS_EXPERIENCES
    ) {
      return 'additional';
    }
    return null;
  }, [data, itemAsJSON]);

  const isCourse = useMemo(() => {
    const { type } = data;
    if (!itemAsJSON || !type) {
      return '';
    }
    return type === filtersOptions.FILTERS_COURSES;
  }, [itemAsJSON, data]);

  const itemName = useMemo(() => {
    const { type } = data;
    if (!itemAsJSON || !type) {
      return '';
    }

    if (type === filtersOptions.FILTERS_COURSES) {
      return itemAsJSON.coursecampus.course.name;
    }
    if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
      return `${itemAsJSON.name} - ${itemAsJSON.type} - ${itemAsJSON.regime}`;
    }
    if (
      type === filtersOptions.FILTERS_INSURANCES ||
      type === filtersOptions.FILTERS_ADDONS ||
      type === filtersOptions.FILTERS_EXPERIENCES
    ) {
      return itemAsJSON.name;
    }
    return '';
  }, [data, itemAsJSON]);

  const itemSchool_id = useMemo(() => {
    if (!itemAsJSON) {
      return '';
    }

    return data?.type === filtersOptions.FILTERS_COURSES
      ? itemAsJSON.coursecampus.campus.school_id
      : itemAsJSON.campus?.school_id ||
          itemAsJSON.campus?.campus?.school_id ||
          undefined;
  }, [data?.type, itemAsJSON]);

  const itemPricePeriod = useMemo(
    () => (itemAsJSON ? itemAsJSON.pricePeriod : ''),
    [itemAsJSON]
  );

  const itemNameSchool = useMemo(() => {
    if (!data.type || itemAsJSON) {
      return '';
    }

    return data.type === filtersOptions.FILTERS_COURSES
      ? itemAsJSON.coursecampus.campus.name
      : itemAsJSON.campus?.name || undefined;
  }, [itemAsJSON, data]);

  const quotesOptions = useCallback((): any[] => {
    let options: Option[] = [];
    if (quotesData.quotes && quotesData.quotes.length > 0) {
      options = quotesData.quotes.map((quote, index) => {
        const isRenewal = quote.courses.some(
          ({
            coursecampus: {
              course: { renewal },
            },
          }: any) => renewal === 1
        );
        return {
          id: `option_${index + 1}`,
          value: index.toString(),
          subtitle: quote.subtitle,
          message:
            isCourse &&
            quote.subtitle !== '' &&
            radioValue !== quote.plugAndPlay
              ? 'Item can not be added on this type of option'
              : 'Items from another school on the same quote are not allowed',
          isDifferentSchools:
            quote.school_id &&
            itemSchool_id &&
            quote.school_id !== itemSchool_id,
          label: quote.config?.name || `Option ${index + 1}`,
          disabled:
            (quote.school_id &&
              itemSchool_id &&
              quote.school_id !== itemSchool_id &&
              quote.plugAndPlay &&
              !isRenewal) ||
            (isCourse &&
              quote.subtitle !== '' &&
              radioValue !== quote.plugAndPlay),
          allyPlus: quote.typeOption === 'allyplus',
        };
      });
    }

    return options;
  }, [isCourse, itemSchool_id, quotesData.quotes, radioValue]);

  const onClickAddOption = (): void => {
    handleAddOption();
    setDefaultCheckedOptions(true);
  };

  const onClickCloseModal = (): void => {
    if (isLoading === false) {
      setDefaultCheckedOptions(false);
      handleCloseModal();
      // setRadioValue(initialRadioValue);
    }
  };

  const onClickAddSelected = (): void => {
    setDefaultCheckedOptions(false);
  };

  const onClickContactButton = async () => {
    if (user.role === 'admin') {
      window.open(`${process.env.REACT_APP_NG_URL}/settings`);
      return;
    }

    api
      .get('/sendmailnewrequestallyplus', {
        params: {
          userName: user.name,
          agencyName: user.office.account.agency_name,
        },
      })
      .then(() => {
        addToast({
          title: 'Sucesso',
          description:
            'Breve um agente entrará em contato com mais informações.',
          type: 'success',
        });
      })
      .catch((e: any) => {
        addToast({
          title: 'Erro',
          description:
            e.message || 'Occoreu um erro. Por favor, tente novamente.',
          type: 'error',
        });
      })
      .finally(handleCloseModal);
  };

  const onClickTalkButton = () =>
    window.open('https://calendly.com/ricardolemos');

  const handleClick = useCallback(
    (field: string) => {
      if (!new Set(submittableField).has(field)) {
        setSubmittableField([...submittableField, field]);
      }
    },
    [setSubmittableField, submittableField]
  );

  const fetchWarnings = useCallback(async (): Promise<void> => {
    if (
      itemAsJSON &&
      data?.type === filtersOptions.FILTERS_COURSES &&
      warningSearch.current !== data.id
    ) {
      warningSearch.current = data.id;
      const res = await api.get(`/warning/${data.id}`);
      setWarnings(res.data);

      await api
        .get(`/firstprice/${itemAsJSON.id}`, {
          params: {
            country: quotesData.student.nationalityCountry,
            courseStartDateFrom: format(
              parseJSON(programStartDate),
              'dd/MM/yyyy'
            ),
            duration: itemAsJSON.coursecampus.duration,
            enrolDate: format(parseJSON(programStartDate), 'dd/MM/yyyy'),
            student_id: quotesData.student.id,
          },
        })
        .then(response => {
          if (!programStartDate) return;
          const courseStartYear = programStartDate.getFullYear();
          const thisDate = new Date();
          const thisYear = thisDate.getFullYear();

          if (courseStartYear > thisYear) {
            setIsWarned(true);
            setFutureCourse(true);
            if (response.data.price) {
              setFuturePrice(true);
            } else {
              setFuturePrice(false);
            }
          } else {
            setFuturePrice(false);
            setFutureCourse(false);
          }
        });
    }
  }, [
    data.id,
    data.type,
    itemAsJSON,
    programStartDate,
    quotesData.student.id,
    quotesData.student.nationalityCountry,
    warningSearch,
  ]);

  const handleStudyPeriodChange = useCallback(
    (value: number) => {
      setNewDuration(value);

      if (newProgramStartDate) {
        const calcNewEndDate = calculateEndDate(
          'Courses',
          newProgramStartDate,
          value,
          itemPricePeriod
        );
        setNewEndDate(calcNewEndDate);
      }

      const calcNewEndDate = calculateEndDate(
        'Courses',
        programStartDate,
        value,
        itemPricePeriod
      );
      setNewEndDate(calcNewEndDate);
    },
    [calculateEndDate, itemPricePeriod, newProgramStartDate, programStartDate]
  );

  const triggerChange = useCallback(
    async (value: number) => {
      data.duration = value;
      await handleDurationChange(data as ListItemFormData, false);
      setAddJSON(true);
    },
    [data, handleDurationChange]
  );

  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      setNewProgramStartDate(date as Date);
      if (date) {
        const calcNewEndDate = calculateEndDate(
          'Courses',
          date,
          newDuration ?? 0,
          itemPricePeriod
        );
        setNewEndDate(calcNewEndDate);
      }
    },
    [calculateEndDate, itemPricePeriod, newDuration]
  );

  const handleSearch = useCallback(
    tabType => {
      const startDate = newProgramStartDate
        ? newProgramStartDate
        : programStartDate;
      mockDataForFetch.courseDuration = data.duration.toString();
      mockDataForFetch.accommodationDuration = data.duration.toString();
      mockDataForFetch.insuranceDuration = data.duration.toString();
      mockDataForFetch.addOnDuration = data.duration.toString();
      startDate.toDateString();
      mockDataForFetch.courseStartDate = startDate.toISOString();
      switch (tabType) {
        case filtersOptions.FILTERS_ACCOMMODATIONS:
          fetchDataForModal([], mockDataForFetch, tabType);
          break;
        case filtersOptions.FILTERS_INSURANCES:
          fetchDataForModal([], mockDataForFetch, tabType);
          break;
        case filtersOptions.FILTERS_ADDONS:
          fetchDataForModal([], mockDataForFetch, tabType);
          break;
        default:
          console.log('Erro ao realizar fetch');
      }
    },
    [data, fetchDataForModal, newProgramStartDate, programStartDate]
  );

  const toggleCollapsibleOpened = useCallback(
    (tab: string) => {
      setCurrentFiltersTab(tab);
      setIsCourseFiltersOpen(
        tab === filtersOptions.FILTERS_COURSES && !isCourseFiltersOpen
      );
      setIsAccommodationFiltersOpen(
        tab === filtersOptions.FILTERS_ACCOMMODATIONS &&
          !isAccommodationFiltersOpen
      );
      setIsInsuranceFiltersOpen(
        tab === filtersOptions.FILTERS_INSURANCES && !isInsuranceFiltersOpen
      );
      setIsAddOnFiltersOpen(
        tab === filtersOptions.FILTERS_ADDONS && !isAddOnFiltersOpen
      );

      updateCollapsiblesOverflow(tab);
    },
    [
      isAccommodationFiltersOpen,
      isAddOnFiltersOpen,
      isCourseFiltersOpen,
      isInsuranceFiltersOpen,
    ]
  );

  const handleClickAddItemToQuote = useCallback(
    async (choosenFeeData: string, type: string) => {
      const feeJSON = { ...data };
      feeJSON.id = choosenFeeData.id.toString();
      feeJSON.type = type;
      feeJSON.data = JSON.stringify(choosenFeeData);

      const quoteData = {
        allyPlus: radioValue,
        duration: data.duration,
        endDate: newEndDate
          ? formatDate(newEndDate)
          : formatDate(courseEndDate),
        quotes: ['0'],
      };

      await handleAddItemToQuote(feeJSON, quoteData);
    },
    [courseEndDate, data, handleAddItemToQuote, newEndDate, radioValue]
  );

  const handleAditionalItem = useCallback(
    async (item, itemTabType) => {
      switch (itemTabType) {
        case filtersOptions.FILTERS_ACCOMMODATIONS: {
          selectedAccommodationsId.includes(item.id)
            ? console.log('delete')
            : await handleClickAddItemToQuote(item, itemTabType);
          setSelectedAccommodationsId(prevSelectedAccommodationIsds =>
            prevSelectedAccommodationIsds.includes(item.id)
              ? prevSelectedAccommodationIsds.filter(id => id !== item.id)
              : [...prevSelectedAccommodationIsds, item.id]
          );
          break;
        }
        case filtersOptions.FILTERS_INSURANCES: {
          selectedInsurancesId.includes(item.id)
            ? console.log('delete')
            : await handleClickAddItemToQuote(item, itemTabType);
          setSelectedInsurancesId(prevSelectedInsuranceIsds =>
            prevSelectedInsuranceIsds.includes(item.id)
              ? prevSelectedInsuranceIsds.filter(id => id !== item.id)
              : [...prevSelectedInsuranceIsds, item.id]
          );
          break;
        }
        case filtersOptions.FILTERS_ADDONS: {
          selectedAddonsId.includes(item.id)
            ? console.log('delete')
            : await handleClickAddItemToQuote(item, itemTabType);
          setSelectedAddonsId(prevSelectedAddonIsds =>
            prevSelectedAddonIsds.includes(item.id)
              ? prevSelectedAddonIsds.filter(id => id !== item.id)
              : [...prevSelectedAddonIsds, item.id]
          );
          break;
        }
        case filtersOptions.FILTERS_OTHERS: {
          selectedSuggestiveFeeId.includes(item.id)
            ? console.log('delete')
            : await handleClickAddItemToQuote(
                item,
                filtersOptions.FILTERS_ADDONS
              );
          setSelectedSuggestiveFeeId(prevSelectedSuggestiveFeeIds =>
            prevSelectedSuggestiveFeeIds.includes(item.id)
              ? prevSelectedSuggestiveFeeIds.filter(id => id !== item.id)
              : [...prevSelectedSuggestiveFeeIds, item.id]
          );
          break;
        }
        default: {
          throw new Error('Error adding item');
        }
      }
    },
    [
      handleClickAddItemToQuote,
      selectedAccommodationsId,
      selectedAddonsId,
      selectedInsurancesId,
      selectedSuggestiveFeeId,
    ]
  );

  useEffect(() => {
    if (addJSON === true) {
      const newPrice = resultItems.find(
        result => result.id === Number(data.id)
      );
      newData.current = newPrice;
      setAddJSON(false);
    }
  }, [addJSON, data.id, resultItems]);

  useEffect(() => {
    if (!data.id) return;

    quotesData?.quotes?.forEach(quote => {
      quote.subtitle = '';
      if (quote.courses.length > 0) {
        if (
          quote.courses[0].course?.campus.city &&
          quote.courses[0].course?.campus.country
        ) {
          quote.subtitle = `${quote.courses[0].course.campus.city.name} - ${
            quote.courses[0].course.campus.school.name
          } - ${quote.courses[0].course.course.name} (${
            quote.courses[0].duration
          } ${quote.courses[0].pricePeriod}${
            quote.courses[0].duration > 1 ? 's' : ''
          })`;
        } else if (quote.courses[0].course?.campus.country) {
          quote.subtitle = `${quote.courses[0].course.campus.country.name} - ${
            quote.courses[0].course.campus.school.name
          } - ${quote.courses[0].course.course.name} (${
            quote.courses[0].duration
          } ${quote.courses[0].pricePeriod}${
            quote.courses[0].duration > 1 ? 's' : ''
          })`;
        } else if (
          quote.courses[0].coursecampus?.campus?.city &&
          quote.courses[0].coursecampus?.campus?.country
        ) {
          quote.subtitle = `${
            quote.courses[0].coursecampus?.campus?.city?.name
          } - ${quote.courses[0].coursecampus?.campus?.school?.name} - ${
            quote.courses[0].coursecampus?.course?.name
          } (${quote.courses[0].coursecampus?.duration} ${
            quote.courses[0]?.pricePeriod
          }${quote.courses[0].coursecampus?.duration > 1 ? 's' : ''})`;
        } else if (quote.courses[0].coursecampus?.campus?.country) {
          quote.subtitle = `${
            quote.courses[0].coursecampus?.campus?.country?.name
          } - ${quote.courses[0].coursecampus?.campus?.school?.name} - ${
            quote.courses[0].coursecampus?.course?.name
          } (${quote.courses[0].coursecampus?.duration} ${
            quote.courses[0]?.pricePeriod
          }${quote.courses[0].coursecampus?.duration > 1 ? 's' : ''})`;
        } else if (quote.courses[0].course) {
          quote.subtitle = `${
            quote.courses[0].course?.course.campus.country.name
          } - ${quote.courses[0].course?.course.campus.school.name} - ${
            quote.courses[0].course.course.name
          } (${quote.courses[0].duration} ${quote.courses[0].pricePeriod}${
            quote.courses[0].duration > 1 ? 's' : ''
          })`;
        }

        if (
          quote.accommodations.length > 0 &&
          !quote.subtitle.includes('Accommodation')
        ) {
          quote.subtitle += ' + Accommodation';
        }

        if (quote.fees.length > 0) {
          quote.fees.forEach((item: { category_id: number }) => {
            if (
              item.category_id === 4 &&
              !quote.subtitle.includes('Insurance')
            ) {
              quote.subtitle += ' + Insurance';
            }
          });
          quote.fees.forEach((item: { category_id: number }) => {
            if (item.category_id !== 4 && !quote.subtitle.includes('Add-ons')) {
              quote.subtitle += ' + Add-ons';
            }
          });
        }
      }

      if (
        !quote.subtitle &&
        quote.accommodations.length > 0 &&
        quote.courses.length === 0
      ) {
        quote.subtitle = `${quote.accommodations[0].city?.name} - ${
          quote.accommodations[0].partner
        } - ${
          quote.accommodations[0].name
        }(${`${quote.accommodations[0].duration} ${quote.accommodations[0].pricePeriod}`}${
          quote.accommodations[0].duration > 1 ? 's' : ''
        })`;

        if (quote.fees.length > 0) {
          quote.fees.forEach((item: { category_id: number }) => {
            if (
              item.category_id === 4 &&
              !quote.subtitle.includes('Insurance')
            ) {
              quote.subtitle += ' + Insurance';
            }
          });
          quote.fees.forEach((item: { category_id: number }) => {
            if (item.category_id !== 4 && !quote.subtitle.includes('Add-ons')) {
              quote.subtitle += ' + Add-ons';
            }
          });
        }
      }

      if (
        !quote.subtitle &&
        quote.fees.length > 0 &&
        quote.accommodations.length === 0 &&
        quote.courses.length === 0
      ) {
        quote.subtitle = `${quote.fees[0].partner} - ${
          quote.fees[0].name
        } (${`${quote.fees[0].duration} ${quote.fees[0].pricePeriod}`}${
          quote.fees[0].duration > 1 ? 's' : ''
        })`;
      }
    });

    if (
      data?.type === filtersOptions.FILTERS_COURSES &&
      (!warningSearch || warningSearch.current !== data.id)
    ) {
      fetchWarnings();
    }
  }, [
    data.id,
    data.type,
    programStartDate,
    quotesData?.quotes,
    quotesData.student.id,
    quotesData.student.nationalityCountry,
    quotesOptions,
    warningSearch,
    itemAsJSON,
    fetchWarnings,
  ]);

  useEffect(() => {
    if (
      (warnings.length === 0 && !futureCourse) ||
      data?.type !== filtersOptions.FILTERS_COURSES
    ) {
      setIsWarned(false);
    } else {
      setIsWarned(true);
    }
  }, [data?.type, futureCourse, warnings]);

  // useEffect(() => {
  //   if ((isItemAllyPlus && isItemBackoffice) || isItemAllyPlus) {
  //     radioValue(1);
  //   } else {
  //     setRadioValue(0);
  //   }
  // }, [isItemAllyPlus, isItemBackoffice]);

  const handleOpen = () => {
    // setRadioValue(initialRadioValue);
  };

  const calcModalPadding = () => {
    if (typeof quotesData.quotes !== 'undefined') {
      const quoteSize = quotesData?.quotes.length * 90;
      const warningsSize = !warnings.length ? 0 : warnings.length * 90;
      return !warnings.length
        ? `${quoteSize}px`
        : `${quoteSize + warningsSize}px`;
    }
    return '500';
  };

  function Price(variable) {
    return <div>{variable}</div>;
  }

  useEffect(() => {
    if (!isOpen) {
      accommodationItems.length = 0;
      insuranceItems.length = 0;
      addonsItems.length = 0;
    }
  }, [accommodationItems, addonsItems, insuranceItems, isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={handleOpen}
      onRequestClose={onClickCloseModal}
      style={{
        overlay: {
          backgroundColor: 'var(--overlay)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto',
          paddingTop: calcModalPadding(),
        },
        content: {
          position: 'initial',
          padding: 0,
          border: '1px solid var(--divider)',
          borderRadius: 0,
          boxShadow: 'var(--modal-shadow)',
          overflow: 'hidden auto',
          maxHeight: '1000px',
        },
      }}
    >
      <ContainerAddModal isWarned={isWarned}>
        <Form
          ref={modalFormRef}
          onSubmit={handleAddItemToQuoteSubmit}
          style={{ paddingBottom: '20px' }}
        >
          <ModalHeader>
            <span>Add Item to Quotes</span>
            <Button
              type="button"
              className="btn-transparent"
              onClick={onClickCloseModal}
            >
              <MdClose size={20} />
            </Button>
          </ModalHeader>

          {(radioValue === '1' && userType === 'backoffice') ||
          (isItemAllyPlus && !isItemBackoffice && userType === 'backoffice') ? (
            <ModalContent className="block">
              <div style={{ width: '45%' }}>
                <h1>OPS!</h1>
                <p>
                  <strong>
                    Parece que sua conta ainda não tem permissão para vendas com
                    Ally+
                  </strong>
                </p>
                <p>
                  Para ter acesso os benefícios do Ally+, clique no botão abaixo
                  para ativar
                </p>
                <Button
                  type="button"
                  onClick={onClickContactButton}
                  className="ally-plus mb-5"
                >
                  {user.role === 'admin' ? 'Ativar Ally+' : 'Solicitar Ally+'}
                </Button>

                {user.role === 'admin' && (
                  <>
                    <p>
                      <strong>Dúvidas de como funciona?</strong>
                    </p>
                    <p>
                      Agende uma conversa com nosso especialista ou{' '}
                      <a
                        href="https://allyhub.co/ally_plus.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        acesse o site
                      </a>{' '}
                      do Ally+
                    </p>
                    <Button
                      type="button"
                      onClick={onClickTalkButton}
                      className="mb-3"
                    >
                      Agendar conversa
                    </Button>
                  </>
                )}
              </div>
              <div style={{ width: '45%' }}>
                <div className="ally-plus-image logo">
                  <img src={allyPlusLogo} alt="Ally+ foto" />
                </div>
                <div className="ally-plus-image main">
                  <img src={allyPlusImage} alt="Ally+ foto" />
                </div>
              </div>
            </ModalContent>
          ) : (
            <>
              <ModalContent
                style={{ borderBottom: '1px solid var(--divider)' }}
              >
                <span>Selected program</span>
                <h3>{itemName}</h3>
                <small>{itemNameSchool}</small>

                {itemType && itemType === 'courses' && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '3rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '75%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '23%',
                        }}
                      >
                        <label htmlFor="Teste">Choose a study period:</label>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            height: '37px',
                          }}
                        >
                          <Duration key={`duration_${data.id}`}>
                            <Input
                              defaultValue={data?.duration}
                              type="number"
                              name="duration"
                              id={`duration_${data.id}`}
                              onChange={e => {
                                handleStudyPeriodChange(
                                  (e.target.value as unknown) as number
                                );
                                clearTimeout(wait_timer);
                                wait_timer = setTimeout(() => {
                                  triggerChange(
                                    (e.target.value as unknown) as number
                                  );
                                }, WAIT_INTERVAL);
                              }}
                              isDisabled={submittableField.includes(
                                filtersOptions.FILTERS_COURSES
                              )}
                            />
                          </Duration>
                          <p style={{ marginLeft: '5px', marginBottom: '0px' }}>
                            {`${itemPricePeriod}(s)`}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '23%',
                        }}
                      >
                        <label htmlFor="Teste">Choose a start date:</label>
                        <DateContainer
                          dateInfoValidation={dateInfo}
                          isErrored={false}
                          isDisabled={submittableField.includes(
                            filtersOptions.FILTERS_COURSES
                          )}
                        >
                          <ReactDatePicker
                            disabled={submittableField.includes(
                              filtersOptions.FILTERS_COURSES
                            )}
                            selected={
                              newProgramStartDate
                                ? newProgramStartDate
                                : programStartDate
                            }
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => {
                              handleStartDateChange(date as Date);
                            }}
                            required
                          />
                        </DateContainer>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '23%',
                        }}
                      >
                        <label htmlFor="Teste">End date:</label>
                        <Select
                          name="endDate"
                          isDisabled
                          value={
                            newEndDate
                              ? {
                                  value: `${formatDate(newEndDate)}`,
                                  label: `${formatDate(newEndDate)}`,
                                }
                              : {
                                  value: `${formatDate(courseEndDate)}`,
                                  label: `${formatDate(courseEndDate)}`,
                                }
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: '25%' }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        {/* Prices rendering */}
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `tuition: ${
                              itemAsJSON.coursecampus.totalThisCourse.currency
                            } ${formatPrice(
                              itemAsJSON.coursecampus.totalThisCourse.onlyCourse
                            )}`}
                        </span>
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `enrol: ${
                              itemAsJSON.coursecampus.totalThisCourse.currency
                            } ${formatPrice(
                              itemAsJSON.coursecampus.totalThisCourse.onlyEnrol
                            )}`}
                        </span>
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `material: ${
                              itemAsJSON.coursecampus.totalThisCourse.currency
                            } ${formatPrice(
                              itemAsJSON.coursecampus.totalThisCourse
                                .onlyMaterial
                            )}`}
                        </span>
                      </div>
                      <span
                        style={{
                          float: 'right',
                          marginTop: '10px',
                          fontSize: '1.6rem',
                        }}
                      >
                        {itemAsJSON &&
                          `${
                            itemAsJSON.coursecampus.totalThisCourse.currency
                          } ${formatPrice(
                            itemAsJSON.coursecampus.totalThisCourse.value
                          )}`}
                      </span>
                    </div>
                  </div>
                )}
                {itemType && itemType === 'accommodations' && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '3rem',
                    }}
                  >
                    <div style={{ width: '40%', float: 'left' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          {itemPricePeriod !== 'fixed' &&
                            `${data?.duration} ${itemAsJSON.typePeriod}(s)`}
                        </span>
                        <span>{itemPricePeriod === 'fixed' && 'fixed'}</span>
                        <span>
                          {`Checkin: ${formatDate(programStartDate)}`}
                        </span>
                      </div>
                    </div>
                    <div style={{ width: '60%', float: 'right' }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `value: ${
                              itemAsJSON.totalThisAccommodation.currency
                            } ${formatPrice(
                              itemAsJSON.totalThisAccommodation
                                .onlyAccommodation
                            )}`}
                        </span>
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `placement fee: ${
                              itemAsJSON.totalThisAccommodation.currency
                            } ${formatPrice(
                              itemAsJSON.totalThisAccommodation.onlyEnrol
                            )}`}
                        </span>
                      </div>
                      <span
                        style={{
                          float: 'right',
                          marginTop: '10px',
                          fontSize: '1.6rem',
                        }}
                      >
                        {itemAsJSON &&
                          `${
                            itemAsJSON.totalThisAccommodation.currency
                          } ${formatPrice(
                            itemAsJSON.totalThisAccommodation.valueWithoutSR
                          )}`}
                      </span>
                    </div>
                  </div>
                )}
                {itemType && itemType === 'additional' && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '3rem',
                    }}
                  >
                    <div style={{ width: '40%', float: 'left' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          {itemPricePeriod !== 'fixed' &&
                            `${data?.duration} ${itemAsJSON.typePeriod}(s)`}
                        </span>
                        <span>{itemPricePeriod === 'fixed' && 'fixed'}</span>
                      </div>
                    </div>
                    <div style={{ width: '60%', float: 'right' }}>
                      <span
                        style={{
                          float: 'right',
                          fontSize: '1.6rem',
                        }}
                      >
                        {itemAsJSON && itemAsJSON.totalThisFee
                          ? `${itemAsJSON.totalThisFee.currency} ${formatPrice(
                              itemAsJSON.totalThisFee.value
                            )}`
                          : `${
                              itemAsJSON.totalThisExperience.currency
                            } ${formatPrice(
                              itemAsJSON.totalThisExperience.value
                            )}`}
                      </span>
                    </div>
                  </div>
                )}
                <ButtonContainer>
                  <Button
                    loading={isLoading}
                    loadingWidth={19}
                    type="submit"
                    className="add-selected-quotes-btn"
                    onClick={() => {
                      onClickAddSelected;
                    }}
                    style={{ width: '20rem', height: '4.5rem' }}
                    disabled={submittableField.includes(
                      filtersOptions.FILTERS_COURSES
                    )}
                  >
                    Prosseguir
                  </Button>
                </ButtonContainer>
              </ModalContent>

              <ModalContent style={{ display: 'none' }}>
                <div className="form-group">
                  <label htmlFor="quotes">
                    Select quotes to add this item:
                  </label>
                  {!!warnings.length &&
                    !futureCourse &&
                    !futurePrice &&
                    data?.type === 'Courses' && (
                      <div>
                        <Warnings futurePrice={false} futureCourse={false}>
                          {warnings}
                        </Warnings>
                      </div>
                    )}
                  {!!warnings.length &&
                    futureCourse &&
                    data?.type === 'Courses' && (
                      <div>
                        <Warnings
                          futurePrice={futurePrice}
                          futureCourse={futureCourse}
                        >
                          {warnings}
                        </Warnings>
                      </div>
                    )}
                  {!warnings.length &&
                    futureCourse &&
                    data?.type === 'Courses' && (
                      <div>
                        <Warnings
                          futurePrice={futurePrice}
                          futureCourse={futureCourse}
                        >
                          {warnings}
                        </Warnings>
                      </div>
                    )}
                  <QuoteOption
                    options={quotesOptions()}
                    name="quotes"
                    className="quoteOption"
                    defaultChecked
                  />
                </div>
                {fiveOptions ? (
                  <div style={{ textAlign: 'center' }}>
                    <p>You can only add up to 5 options in a quote!</p>
                  </div>
                ) : (
                  <Button
                    loading={isAddOptionLoading}
                    loadingColor="var(--primary)"
                    type="button"
                    className="btn-outline add-option-btn"
                    onClick={onClickAddOption}
                    tooltip="Add option"
                    style={{ visibility: 'hidden' }}
                  >
                    <MdAdd size={16} />
                    option
                  </Button>
                )}
              </ModalContent>

              {/* collapsibles */}
              {submittableField.includes(filtersOptions.FILTERS_COURSES) && (
                <ModalContent>
                  <br />
                  <p>Choose another services</p>
                  {!submittableField.includes(
                    filtersOptions.FILTERS_COURSES
                  ) && (
                    <small style={{ color: 'red' }}>
                      You should add a course before adding aditional services
                    </small>
                  )}
                  <CollapsibleWrapper>
                    <Collapsible
                      className={`filters-${filtersOptions.FILTERS_ACCOMMODATIONS}`}
                      openedClassName={`filters-${filtersOptions.FILTERS_ACCOMMODATIONS}`}
                      trigger={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Trigger
                          type="Accommodations"
                          isOpen={isAccommodationFiltersOpen}
                        />
                      }
                      triggerTagName="div"
                      transitionTime={300}
                      open={isAccommodationFiltersOpen}
                      handleTriggerClick={() => {
                        toggleCollapsibleOpened(
                          filtersOptions.FILTERS_ACCOMMODATIONS
                        );
                        if (accommodationItems.length === 0) {
                          handleSearch(filtersOptions.FILTERS_ACCOMMODATIONS);
                        }
                      }}
                      triggerDisabled={
                        !submittableField.includes(
                          filtersOptions.FILTERS_COURSES
                        )
                      }
                    >
                      <ExpandableContent>
                        <div>
                          {searchLoading && (
                            <Loading
                              loading={searchLoading}
                              color="var(--primary)"
                            />
                          )}
                          {accommodationItems.map((accommodation, index) => {
                            const flagImg = `${process.env.REACT_APP_NG_URL}/images/flags/${accommodation.accommodation.city.country?.flagName}.png`;
                            if (
                              accommodation.campus?.campus?.school_id ===
                                itemAsJSON.coursecampus?.campus?.school_id &&
                              itemAsJSON.campus_id ===
                                accommodation.campus?.campus_id
                            ) {
                              return (
                                <IndividualItem
                                  key={index}
                                  onClick={() => {
                                    handleAditionalItem(
                                      accommodation,
                                      filtersOptions.FILTERS_ACCOMMODATIONS
                                    );
                                  }}
                                  isSelected={selectedAccommodationsId.includes(
                                    accommodation.id
                                  )}
                                >
                                  <div>
                                    {flagImg ? (
                                      <img src={flagImg} alt="" height="20px" />
                                    ) : (
                                      ' '
                                    )}{' '}
                                    {accommodation.accommodation.city.name}
                                    <p>
                                      {accommodation.campus
                                        ? accommodation.campus.campus.name
                                        : ''}{' '}
                                      - {accommodation.partner}
                                    </p>
                                    <strong>
                                      {accommodation.accommodation.name}
                                      {' - '}
                                      {accommodation.type} Room
                                      {' - '}
                                      {accommodation.bathroom} Bathroom
                                      {' - '}
                                      {accommodation.regime}
                                    </strong>
                                  </div>
                                  <ItemPrice>
                                    {
                                      accommodation.accommodation.currency
                                        .symbol
                                    }{' '}
                                    {accommodation.price
                                      ? accommodation.totalThisAccommodation
                                          .value
                                      : ''}
                                    ,00
                                  </ItemPrice>
                                </IndividualItem>
                              );
                            }
                            return null; // Ou retorne algum elemento alternativo se necessário
                          })}
                        </div>
                      </ExpandableContent>
                    </Collapsible>
                    <Button
                      onClick={() => {
                        setCurrentFiltersTab(
                          filtersOptions.FILTERS_ACCOMMODATIONS
                        );
                        handleClick(filtersOptions.FILTERS_ACCOMMODATIONS);
                      }}
                      disabled={
                        !submittableField.includes(
                          filtersOptions.FILTERS_COURSES
                        ) ||
                        submittableField.includes(
                          filtersOptions.FILTERS_ACCOMMODATIONS
                        )
                      }
                      style={{ maxHeight: '40px', minWidth: '95px' }}
                    >
                      No, thanks
                    </Button>
                  </CollapsibleWrapper>

                  {/* Insurances */}
                  <CollapsibleWrapper>
                    <Collapsible
                      className={`filters-${filtersOptions.FILTERS_INSURANCES}`}
                      openedClassName={`filters-${filtersOptions.FILTERS_INSURANCES} `}
                      trigger={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Trigger
                          type="Insurances"
                          isOpen={isInsuranceFiltersOpen}
                        />
                      }
                      triggerTagName="div"
                      transitionTime={300}
                      open={isInsuranceFiltersOpen}
                      handleTriggerClick={() => {
                        toggleCollapsibleOpened(
                          filtersOptions.FILTERS_INSURANCES
                        );
                        if (insuranceItems.length === 0) {
                          handleSearch(filtersOptions.FILTERS_INSURANCES);
                        }
                      }}
                      style={{ color: 'red' }}
                      triggerDisabled={
                        !submittableField.includes(
                          filtersOptions.FILTERS_COURSES
                        )
                      }
                    >
                      <ExpandableContent>
                        <div>
                          {searchLoading && (
                            <Loading
                              loading={searchLoading}
                              color="var(--primary)"
                            />
                          )}
                          {insuranceItems.map((insurance, index) => {
                            const flagImg = `${process.env.REACT_APP_NG_URL}/images/flags/${insurance.campus.city.country?.flagName}.png`;
                            return (
                              <IndividualItem
                                key={index}
                                onClick={() => {
                                  handleAditionalItem(
                                    insurance,
                                    filtersOptions.FILTERS_INSURANCES
                                  );
                                }}
                                isSelected={selectedInsurancesId.includes(
                                  insurance.id
                                )}
                              >
                                <div>
                                  {flagImg ? (
                                    <img src={flagImg} alt="" height="20px" />
                                  ) : (
                                    ''
                                  )}{' '}
                                  {insurance.city.name}
                                  <p>
                                    {insurance.campus.name} -{' '}
                                    {insurance.campus.school.name}
                                  </p>
                                  <strong>{insurance.name}</strong>
                                </div>
                                {insurance.price ? (
                                  <ItemPrice className="currency">
                                    {insurance.currency.symbol}
                                    {insurance.price
                                      ? insurance.price.value
                                      : ''}
                                    ,00
                                  </ItemPrice>
                                ) : (
                                  <Loading
                                    loading={searchLoading}
                                    color="var(--primary)"
                                  />
                                )}
                              </IndividualItem>
                            );
                          })}
                        </div>
                      </ExpandableContent>
                    </Collapsible>
                    <Button
                      onClick={() => {
                        setCurrentFiltersTab(filtersOptions.FILTERS_INSURANCES);
                        handleClick(filtersOptions.FILTERS_INSURANCES);
                      }}
                      disabled={
                        !submittableField.includes(
                          filtersOptions.FILTERS_COURSES
                        ) ||
                        submittableField.includes(
                          filtersOptions.FILTERS_INSURANCES
                        )
                      }
                      style={{ maxHeight: '40px', minWidth: '95px' }}
                    >
                      No, thanks
                    </Button>
                  </CollapsibleWrapper>

                  {/* Add-ons */}
                  <CollapsibleWrapper>
                    <Collapsible
                      className={`filters-${filtersOptions.FILTERS_ADDONS}`}
                      openedClassName={`filters-${filtersOptions.FILTERS_ADDONS}`}
                      trigger={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <>
                          <Trigger type="Add-ons" isOpen={isAddOnFiltersOpen} />
                        </>
                      }
                      triggerTagName="div"
                      transitionTime={300}
                      open={isAddOnFiltersOpen}
                      handleTriggerClick={() => {
                        toggleCollapsibleOpened(filtersOptions.FILTERS_ADDONS);
                        if (addonsItems.length === 0) {
                          // handleSearch(true, true, filtersOptions.FILTERS_ADDONS);
                          handleSearch(filtersOptions.FILTERS_ADDONS);
                        }
                      }}
                      triggerDisabled={
                        !submittableField.includes(
                          filtersOptions.FILTERS_COURSES
                        )
                      }
                    >
                      <ExpandableContent>
                        <div>
                          {searchLoading && (
                            <Loading
                              loading={searchLoading}
                              color="var(--primary)"
                            />
                          )}
                          {addonsItems.length !== 0 &&
                            editingItemSuggestiveFees.map(
                              (suggestiveFee, index) => {
                                return (
                                  <IndividualItem
                                    key={index}
                                    onClick={() => {
                                      handleAditionalItem(
                                        suggestiveFee,
                                        filtersOptions.FILTERS_OTHERS
                                      );
                                    }}
                                    isSelected={selectedSuggestiveFeeId.includes(
                                      suggestiveFee.id
                                    )}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          alignItems: 'center',
                                          gap: '20px',
                                          minWidth: '300px',
                                          minHeight: 'auto',
                                        }}
                                      >
                                        <p>
                                          {suggestiveFee.campus
                                            ? `${suggestiveFee.campus.name} -`
                                            : ''}
                                          {suggestiveFee.partner}
                                        </p>
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '3px',
                                          }}
                                        >
                                          <MdStarBorder size={15} />
                                          {'  '}
                                          <p>Suggestive Fee</p>
                                        </div>
                                      </div>
                                      <strong>{suggestiveFee.name}</strong>
                                    </div>
                                    <ItemPrice>
                                      {suggestiveFee.totalThisFee.currency}{' '}
                                      {suggestiveFee.totalThisFee.value},00
                                    </ItemPrice>
                                  </IndividualItem>
                                );
                              }
                            )}
                          {addonsItems.map((addon, index) => {
                            const flagImg = `${process.env.REACT_APP_NG_URL}/images/flags/${addon.campus.city.country?.flagName}.png`;
                            if (itemAsJSON.campus_id === addon.campus?.id) {
                              return (
                                <IndividualItem
                                  key={index}
                                  onClick={() => {
                                    handleAditionalItem(
                                      addon,
                                      filtersOptions.FILTERS_ADDONS
                                    );
                                  }}
                                  isSelected={selectedAddonsId.includes(
                                    addon.id
                                  )}
                                >
                                  <div>
                                    {flagImg ? (
                                      <img src={flagImg} alt="" height="20px" />
                                    ) : (
                                      ' '
                                    )}{' '}
                                    {addon.city.name}
                                    <p>
                                      {addon.campus.name} -{' '}
                                      {addon.campus.school.name}
                                    </p>
                                    <strong>{addon.name}</strong>
                                  </div>
                                  <ItemPrice>
                                    {addon.currency.symbol}
                                    {addon.price ? addon.price.value : ''},00
                                  </ItemPrice>
                                </IndividualItem>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </ExpandableContent>
                      <div />
                    </Collapsible>
                    <Button
                      onClick={() => {
                        setCurrentFiltersTab(filtersOptions.FILTERS_ADDONS);
                        handleClick(filtersOptions.FILTERS_ADDONS);
                      }}
                      disabled={
                        !submittableField.includes(
                          filtersOptions.FILTERS_COURSES
                        ) ||
                        submittableField.includes(filtersOptions.FILTERS_ADDONS)
                      }
                      style={{ maxHeight: '40px', minWidth: '95px' }}
                    >
                      No, thanks
                    </Button>
                  </CollapsibleWrapper>
                </ModalContent>
              )}
            </>
          )}

          {/* {!(
            isItemAllyPlus &&
            !isItemBackoffice &&
            userType === 'backoffice'
          ) &&
            isCourse && (
              <RadioInput
                name="allyPlus"
                options={itemsRadioInput}
                sendValue={sendValue}
                valueWasExpired={isValueExpired}
                isSellDirectlyDefault={userType === 'backoffice'}
                className="mt-0"
              />
            )} */}

          {((isValueExpired && radioValue === '1') ||
            (userType === 'allyplus' && isValueExpired)) &&
            userType !== 'backoffice' && (
              <p className="text-center">
                Unavailable on Ally+. Outdated value.
              </p>
            )}

          {isCourse && userType !== 'backoffice' ? (
            <ButtonContainer
              className={`${
                (radioValue === '1' || userType === 'allyplus') && 'ally-plus'
              }`}
            >
              <Button
                loading={isLoading}
                loadingWidth={19}
                type="submit"
                disabled={submittableField.length < 4}
                className="add-selected-quotes-btn"
                onClick={handleFinishAndSaveQuotes}
                style={{ marginTop: '20px' }}
              >
                Finish and Save Quote
              </Button>
              {/* <span>
                {radioValue === 1 || userType === 'allyplus'
                  ? 'Focus on sales, we take care of the rest'
                  : 'You must handle your own operations department'}
              </span> */}
            </ButtonContainer>
          ) : (
            radioValue === '0' &&
            isItemBackoffice && (
              <ButtonContainer>
                <Button
                  loading={isLoading}
                  loadingWidth={19}
                  type="submit"
                  className="add-selected-quotes-btn"
                  disabled
                  onClick={onClickAddSelected}
                >
                  Add
                </Button>
                <span>You must handle your own operations department</span>
              </ButtonContainer>
            )
          )}

          {!isCourse && (
            <ButtonContainer>
              <Button
                loading={isLoading}
                loadingWidth={19}
                type="submit"
                className="add-selected-quotes-btn"
                onClick={() => {
                  onClickAddSelected;
                }}
                disabled
              >
                Add
              </Button>
              <span> </span>
            </ButtonContainer>
          )}
        </Form>
      </ContainerAddModal>
    </ReactModal>
  );
};

export default AddItemToQuoteModal;
