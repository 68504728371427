import styled, { css } from 'styled-components';

interface Props {
  isWarned: boolean;
}

interface IndividualItemProps {
  isSelected: boolean;
}

export const ContainerAddModal = styled.div<Props>`
  width: 800px;
  ${props =>
    props.isWarned &&
    css`
      width: 800px;
    `}

  @media(max-width: 768px) {
    width: 70vw;
  }
`;

export const Container = styled.div`
  width: 570px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider);
  padding: 15px;

  > span {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const ModalContent = styled.div`
  padding: 5px 15px;

  .form-group {
    margin: 20px 0 5px;

    > div {
      margin-top: 10px;
    }
  }

  &.block {
    display: flex;
    justify-content: space-between;

    h1,
    strong {
      color: var(--gray) !important;
    }

    .ally-plus-image {
      img {
        width: 100%;
      }

      &.main {
        width: 100%;
        margin-inline: auto;
      }

      &.logo {
        width: 90px;
        margin-left: calc(100% - 90px);
      }
    }

    button {
      width: initial;

      &.ally-plus {
        background: #e50069;
        border-color: #e50069;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 280px;
  margin: 0 auto;
  text-align: center;
  button {
    border: none;
  }

  button {
    width: 100%;
    height: 50px;
  }

  span {
    color: var(--primary);
  }

  &.ally-plus {
    button {
      background-color: #ff0775;
      border: none;

      &:hover {
        background: #dd0967;
      }
    }

    span {
      color: #ff0775;
    }
  }
`;

export const CollapsibleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1rem;
  padding: 1rem;
  min-height: 5rem;
  overflow-y: hidden;
  background-color: var(--bs-gray-100);
`;

export const ExpandableContent = styled.div`
  margin: 20px 0px;
  padding: 5px;
`;

export const IndividualItem = styled.div<IndividualItemProps>`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 5rem;
  width: 100%;
  margin: 10px 0px;
  background-color: var(--white);
  border: 1px solid var(--divider);
  text-align: left;
  cursor: pointer;
  :hover {
    background-color: var(--outline-hover);
  }

  div {
    max-width: 80%;
  }

  ${props =>
    props.isSelected &&
    css`
      border: 2px solid var(--green);
    `}
`;

export const ItemPrice = styled.div`
  padding: 0px 5px;
  width: 15%;
  font-weight: 700;
  color: var(--primary);
  text-align: end;
`;
