import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import Buttom from '../../components/Button';

export const ActionsButtons = styled.div`
  display: flex;
  flex-direction: line;
  margin-left: 20px;
  min-width: 192px;
  align-items: center;
  justify-content: center;
  column-gap: 52px;

  .actions-buttons {
    display: flex;
    gap: 0.5rem;
  }

  div {
    display: inline-block;
    width: 200px;
  }

  button {
    width: 100%;
    height: 46px;
    &:hover {
      font-weight: bolder;
    }
  }
`;

export const Container = styled.div`
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const Filters = styled.div`
  width: 300px;
  background: var(--white);
  display: flex;
  flex-direction: column;
`;

export const FiltersHeader = styled.div`
  border-bottom: 1px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  > button {
    width: auto;
  }
`;

export const FiltersContent = styled.div`
  flex: 1;
  display: flex;

  > form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .check-invisible {
      display: none;
    }

    .form-group {
      margin-top: 15px;

      label {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }

      .online-check {
        margin-top: 25px;
      }

      .smaller {
        font-size: smaller;
      }

      .inline {
        display: inline;
      }

      .online {
        label {
          font-size: 1.2rem;
          color: var(--gray);
          margin-left: 25px;
          margin-top: -10px;
        }
      }
    }

    .inactive {
      display: none;
    }

    .icon-info-date {
      color: #ffe76c;
      margin-top: 6px;
    }

    .tooltip-info-date {
      width: 150px;
      padding: 8px;
    }

    .tooltip-info-checkbox {
      width: 250px;
      padding: 8px;
    }

    .form-group--column {
      gap: 10px;
      display: flex;
      justify-content: space-between;

      .form-group--date,
      .form-group--regime,
      .form-group--duration {
        flex: 2;
      }

      .form-group--period {
        flex: 2;
      }

      .form-group--duration {
        max-width: 144px;

        input {
          width: 100%;
        }
      }
    }
  }
`;

export const Collapsibles = styled.div`
  flex: 1;
  overflow: auto;
  padding-bottom: 8rem;

  .Collapsible:not(.more-filters) {
    position: relative;

    .Collapsible__trigger:not(.trigger) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      color: var(--black);
      padding: 20px 15px;

      cursor: pointer;

      &.is-closed {
        border-bottom: 1px solid var(--divider);
      }
    }

    .Collapsible__trigger.is-closed + .Collapsible__contentOuter {
      overflow: hidden !important;
    }

    .Collapsible__contentInner:not(.content) {
      padding: 0 15px 20px 15px;
      border-bottom: 1px solid var(--divider);

      > svg {
        position: absolute;
        top: 19px;
        right: 15px;
        color: var(--primary);
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--porcelain);
  border-left: 1px solid var(--divider);
  overflow: auto;
`;

export const ContentHeader = styled.div`
  border-bottom: 1px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  height: 54px;
  padding: 15px;

  > .react-select--is-disabled {
    max-width: 200px;
  }
`;

export const ContentFilters = styled.div`
  display: flex;
  justify-content: space-between;

  form {
    display: flex;
    align-items: flex-start;
    flex: 1;
    max-width: 700px;

    .form-group {
      label {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }

      & + .form-group {
        flex: 1;
        margin-left: 10px;
      }
    }

    .form-group--date {
      max-width: 128px;
    }

    #search-button {
      width: auto;
      margin-top: 21px;
      margin-left: 10px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 1.4rem 0;
  justify-content: space-between;

  > div,
  > button {
    & + div,
    & + button {
      margin-left: 10px;
    }
  }

  .price-range--slider {
    margin: 25px 20px 35px;

    .input-range {
      height: 2.8rem;

      .input-range__label-container {
        font-size: 1.2rem;
        font-family: var(--primary-font);
        color: var(--gray);
      }

      .input-range__label--value {
        top: -2.8rem;

        .input-range__label-container {
          background: var(--primary);
          color: var(--white);
          padding: 1px 7px;
          border-radius: 10px;
          font-weight: 700;
        }
      }

      .input-range__track {
        height: 0.5rem;
        background: var(--light-gray);
        border-radius: 0;
      }

      .input-range__track--active {
        background: var(--primary);
      }

      .input-range__slider {
        background: var(--primary);
        border: 1px solid var(--primary);
      }
    }
  }

  .promotion {
    .promotion--switch {
      margin-top: 8px;

      .promotion--yes,
      .promotion--no {
        color: var(--white);
        font-weight: 700;
        font-size: 1.2rem;
        position: relative;
        top: 5px;
        left: 10px;
      }
    }
  }

  .renewal {
    .renewal--switch {
      .renewal--yes,
      .renewal--no {
        color: var(--white);
        font-weight: 700;
        font-size: 1.2rem;
        position: relative;
        top: 5px;
        left: 10px;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const NoContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Hero = styled.div`
  width: 340px;
  text-align: center;

  > img {
    width: 75px;
  }

  > h2 {
    margin: 30px 0 10px;
  }

  > a:visited {
    color: blue;
  }
  > a:active {
    color: blue;
  }
`;

export const ResultWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const ResultHeader = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 15px;

  > img {
    width: 15px;
  }

  > strong {
    margin: 0 10px;
  }

  > h3 {
    flex: 1;
    margin-left: 20px;

    > small {
      font-weight: normal;
      color: var(--gray);
      margin-left: 10px;
    }
  }

  > button {
    width: auto;
  }
`;

export const Result = styled.div`
  display: block;
  overflow: auto;
  height: 100%;
  padding: 15px;

  #carousel-slider {
    position: relative;

    .react-multi-carousel-dot-list {
      bottom: -35px;

      .react-multi-carousel-dot {
        button {
          border: 0;
          background: var(--light-gray);
          margin-right: 8px;
        }
      }

      .react-multi-carousel-dot--active {
        button {
          background: var(--primary);
        }
      }
    }
  }
`;

export const AllyPlusResult = styled.div`
  background-color: #f5eee3;
  padding: 0px 15px 15px;

  > p {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(right, #e50069, #eb595d, #f59d26);
    margin: 0px;
    font-weight: 700;
    padding: 8px 0px;
  }
`;

export const ListView = styled.div`
  margin-right: 15px;
  margin-bottom: 40px;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    li {
      list-style: none;
      margin: 0 2px;
      background: var(--white);
      border: 1px solid var(--light-gray);
      padding: 5px 0;

      &:hover {
        background: var(--white-hover);
      }

      a {
        font-size: 1.3rem;
        font-weight: 700;
        padding: 5px 12px;
        color: var(--gray);
        text-decoration: none;
        cursor: pointer;
      }

      &.active {
        background: var(--primary);

        a {
          color: var(--white);
        }
      }

      &.disabled {
        opacity: 0.4;

        &:hover {
          background: var(--white);
        }

        a {
          cursor: initial;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--white);
  padding: 15px;
  border-top: 1px solid var(--divider);

  > button {
    width: auto;
  }
`;

export const Student = styled(Buttom)`
  width: initial !important;
  gap: 0.5rem;
  display: flex;
  align-items: end;
  padding: 8px 12px;

  > img {
    width: 1.8rem;
  }
`;

export const InvisibleAllyPlusBtn = styled(Buttom)`
  display: flex;
  align-items: center;
  font-size: 1em;
  background: none;
  border-radius: 10px;
  padding: 7px;
  font-weight: 400;
  border-color: #ff0775;
  color: #ff0775;
  visibility: visible !important;

  :disabled {
    border-color: #ff0775 !important;
    color: #ff0775 !important;
  }

  :hover {
    background: none !important;
    border-color: #ff0775 !important;
    color: #ff0775 !important;
  }
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  display: flex;
  align-items: center;
  font-size: 1em;
  background: none;
  border-color: var(--green);
  border-radius: 10px;
  padding: 7px;
  color: var(--green);
  font-weight: 400;

  &::after {
    display: none;
  }

  :hover {
    color: var(--green);
    background: none;
    border-color: var(--green);
  }

  :active {
    color: var(--green) !important;
    background: none !important;
    border-color: var(--green) !important;
  }

  :disabled {
    color: var(--green) !important;
    background: none !important;
    border-color: var(--green) !important;
    opacity: 0.5;

    :hover {
      cursor: not-allowed !important;
    }
  }

  &.btn.show {
    color: var(--green) !important;
    background: none !important;
    border-color: var(--green) !important;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  width: 300px;
  margin-top: 3px;
  margin-left: -220px;
  padding: 20px;
  border: 2px solid var(--divider);
  border-radius: 10px;
  background-color: white;

  .display-flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-size: 1.2rem;
  }

  span {
    font-size: 1.1rem;
  }
`;
