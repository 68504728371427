import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65rem;

  > div > .text-muted {
    font-size: 12px;
    color: var(--secondary);
    font-style: italic;
    font-weight: initial;
  }

  > .counter {
    flex: 1;
    text-align: end;
    color: var(--primary);
  }
`;
