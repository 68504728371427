/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useCallback, useState, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaPlus, FaInfo, FaChevronDown } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import {
  MdBlock,
  MdLocalOffer,
  MdStar,
  MdToday,
  MdWarning,
} from 'react-icons/md';
import { IoFileTrayFull } from 'react-icons/io5';
import { IoIosPricetags, IoMdCalendar } from 'react-icons/io';
import { FcIdea } from 'react-icons/fc';
import { TiInputChecked } from 'react-icons/ti';
import { FaThumbsUp } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import ModalChangeAge from './ModalChangeAge';
import { useAuth } from '../../hooks/auth';
import formatValue from '../../utils/formatValue';
import { filtersOptions } from '../../shared/constants';
import Loading from '../Loading';
import {
  ListItemFormData,
  CourseCategory,
  CourseNewCategory,
  ExperienceCategory,
  ExperienceDepartureDate,
  ExperienceDepartureDateOption,
} from '../../shared/interfaces';

import 'react-loading-skeleton/dist/skeleton.css';

import Input from '../Input';
import Button from '../Button';
import Select from '../Select';

import {
  Container,
  Content,
  PriceCss,
  Duration,
  Actions,
  PartnerSchool,
  AllyPlusBox,
} from './styles';
import { useWizard } from '../../hooks/wizard';
import Tooltip from '../Tooltip';
import { useToast } from '../../hooks/toast';
import ReportErrorModal from '../../modals/ReportErrorModal';
import allyPlus from '../../assets/allyPlus.png';
import AddedOptionsModal from '../../modals/AddedOptionsModal';

interface cmptToLoadingProps {
  loadedRealValues: boolean;
  children: React.ReactNode;
  openViewItemDescriptionModal?: any;
  openViewItemPricelistModal?: any;
}

const Price: React.FC<cmptToLoadingProps> = ({
  loadedRealValues,
  children,
}) => {
  if (!loadedRealValues) {
    return (
      <div
        style={{
          minWidth: '250px',
        }}
      >
        {/* <Skeleton count={2} inline={false} width={100} /> */}
        <br />
        {/* <Skeleton height={30} inline={false} width={100} /> */}
      </div>
    );
  }

  return <PriceCss>{children}</PriceCss>;
};

const ActionsButtons: React.FC<cmptToLoadingProps> = ({
  loadedRealValues,
  openViewItemDescriptionModal,
  children,
}) => {
  if (!loadedRealValues) {
    return (
      <div className="actions-buttons">
        <Button
          type="button"
          className="btn-white btn-outline btn-outline--gray"
          onClick={openViewItemDescriptionModal}
        >
          <FaInfo />
        </Button>
        <Button
          type="button"
          loading
          loadingWidth={19}
          className="btn-green"
          style={{
            minWidth: '100px',
          }}
        >
          <Loading loading color="var(--white)" />
        </Button>
      </div>
    );
  }

  return <div className="actions-buttons">{children}</div>;
};

interface ListItemProps {
  data: any;
  wasAdded?: boolean;
  onlyRenewal?: boolean;
  isLoading?: boolean;
  isSuggestive?: boolean;
  useInternalFields?: boolean;
  type: string;
  handleDurationChange: (data: ListItemFormData, isSuggestive: boolean) => void;
  handleAddItemToQuote: (data: ListItemFormData) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  data,
  isLoading = false,
  isSuggestive = false,
  onlyRenewal,
  type,
  useInternalFields = false,
  wasAdded = false,
  handleDurationChange,
  handleAddItemToQuote,
}) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isReportErrorModalOpened, setIsReportErrorModalOpened] = useState(
    false
  );
  const [isAddedOptionsModalOpened, setIsAddedOptionsModalOpened] = useState(
    false
  );
  const [selectedDepartureDate, setSelectedDepartureDate] = useState(
    {} as ExperienceDepartureDateOption
  );

  const { addToast } = useToast();
  const { quotesData, updateQuotesData } = useWizard();
  const formRef = useRef<FormHandles>(null);
  const { user, angularPort } = useAuth();
  const WAIT_INTERVAL = 1000;
  const dataAsString = JSON.stringify(data);

  const isIEAccount = user.account_id === 2015;
  const useStudentAge = user.tourStep !== 60;
  const loadedRealValues = data.loadedRealValues || false;

  const isCourse = type === filtersOptions.FILTERS_COURSES;
  const isAccommodation = type === filtersOptions.FILTERS_ACCOMMODATIONS;
  const isInsurance = type === filtersOptions.FILTERS_INSURANCES;
  const isAddons = type === filtersOptions.FILTERS_ADDONS;
  const isExperience = type === filtersOptions.FILTERS_EXPERIENCES;

  const isAvailableWithAllyPlus = data.availableWithAllyPlus as boolean;
  const { showAllyPlusItems } = data;

  const updateStudent = (newStudentData: any): void => {
    updateQuotesData({
      student: { ...quotesData.student, ...newStudentData },
      fees: undefined,
    });
  };

  let wait_timer: any;
  wait_timer = null;
  let price: any;
  let priceOld: any;
  let isMySchool;
  let isPremium;
  let schoolLogo;
  let schoolName;
  let campusName;
  let partnerName;
  let duration: any;
  let durationInput: any;
  let durationType;
  let flagImg;
  let renewal;
  let onshore;
  let online;
  let countryName: any;
  let cityName;
  let itemName;
  let categories;
  let onlyCourse;
  let onlyCourseWithoutSR;
  let onlyCourseWithoutSRFormatted;
  let onlyAccommodation;
  let onlyAccommodationWithoutSR;
  let discountApplied;
  let discountAppliedAccommodation;
  let oldOnlyCourse;
  let placementFeeFormatted;
  let departureDateOptions;
  let priceValidity;
  let hasSpecialRates = false;
  let hasMaterialSR = false;
  let hasEnrolSR = false;
  let hasTuitionSR = false;
  const accommodationHasSpecialRates = false;

  data.showPriceByAge = true;
  data.unavailableAge = false;

  if (isCourse && data.coursecampus.course.newcategory) {
    data.coursecampus.newcategories = [];
    data.coursecampus.newcategories_ids = [];
    data.coursecampus.course.newcategory.map((cat: { new_category: any }) => {
      let father;
      if (cat.new_category.parent_id) {
        if (data.coursecampus.newcategories.length) {
          father = data.coursecampus.newcategories.find(
            (s: { newCategory_id: any }) =>
              s.newCategory_id === cat.new_category.parent_id
          );
          if (father && !father.children) {
            father.children = [];
          }
        }
        if (!father) {
          father = data.coursecampus.course.newcategory.find(
            (s: { newCategory_id: any }) =>
              s.newCategory_id === cat.new_category.parent_id
          );
          father.children = [];
        }
        if (father) {
          father.children.push(cat.new_category);
        }
      }
      if (
        father &&
        !data.coursecampus.newcategories_ids.includes(father.newCategory_id)
      ) {
        data.coursecampus.newcategories_ids.push(father.newCategory_id);
        data.coursecampus.newcategories.push(father);
      }
      return true;
    });
  }

  if (isCourse) {
    if (
      (data.coursecampus.course.ageFrom || data.coursecampus.course.ageTo) &&
      quotesData.student.age === -1
    ) {
      data.showPriceByAge = true;
      // data.showPriceByAge = false;
      if (isIEAccount && useStudentAge) {
        data.showPriceByAge = false;
        data.unavailableAge = true;
      }
    }
    if (
      (data.coursecampus.course.ageFrom || data.coursecampus.course.ageTo) &&
      quotesData.student.age !== -1
    ) {
      if (data.coursecampus.course.ageFrom && data.coursecampus.course.ageTo) {
        if (
          !(
            data.coursecampus.course.ageFrom <= quotesData.student.age &&
            data.coursecampus.course.ageTo >= quotesData.student.age
          )
        ) {
          data.showPriceByAge = true;
          data.unavailableAge = false;
          if (isIEAccount && useStudentAge) {
            data.showPriceByAge = false;
            data.unavailableAge = true;
          }
        }
      } else if (
        data.coursecampus.course.ageFrom &&
        !data.coursecampus.course.ageTo
      ) {
        if (!(data.coursecampus.course.ageFrom <= quotesData.student.age)) {
          data.showPriceByAge = true;
          data.unavailableAge = false;
          if (isIEAccount && useStudentAge) {
            data.showPriceByAge = false;
            data.unavailableAge = true;
          }
        }
      } else if (
        !data.coursecampus.course.ageFrom &&
        data.coursecampus.course.ageTo
      ) {
        if (!(data.coursecampus.course.ageTo >= quotesData.student.age)) {
          data.showPriceByAge = true;
          data.unavailableAge = false;
          if (isIEAccount && useStudentAge) {
            data.showPriceByAge = false;
            data.unavailableAge = true;
          }
        }
      }
    }
    if (
      loadedRealValues &&
      (data.coursecampus.price.srmaterial ||
        data.coursecampus.price.srenrol ||
        data.coursecampus.price.srcourse ||
        data.coursecampus.price.srperiod)
    ) {
      hasSpecialRates = true;
      if (data.coursecampus.price.srmaterial) {
        hasMaterialSR = true;
      }
      if (data.coursecampus.price.srenrol) {
        hasEnrolSR = true;
      }
      if (
        data.coursecampus.price.srcourse ||
        data.coursecampus.price.srperiod
      ) {
        hasTuitionSR = true;
      }
    }

    price = data.coursecampus.price || '';
    priceOld = data.coursecampus.priceOld || '';
    isMySchool = data.coursecampus.account_id === user.account_id;
    isPremium = data.coursecampus.campus.school.schoolpremium;
    data.pricePeriod = data.coursecampus.price?.periodType || data.pricePeriod;
    schoolLogo = `${process.env.REACT_APP_AMZ_API}/base/school-logos/school_${data.coursecampus.campus.school_id}.jpg`;
    schoolName = data.coursecampus.campus.school.name;
    campusName = data.coursecampus.campus.name;
    duration = data.coursecampus.duration || 1;
    durationInput = data.coursecampus.durationInput;

    if (data.coursecampus.campus.country) {
      flagImg = `${process.env.REACT_APP_NG_URL}/images/flags/${data.coursecampus.campus.country?.flagName}.png`;
    } else {
      flagImg = '';
    }
    countryName = data.coursecampus.campus.country
      ? data.coursecampus.campus.country.name
      : '';
    cityName = data.coursecampus.campus.city
      ? data.coursecampus.campus.city.name
      : countryName;
    itemName = data.coursecampus.course.name;

    data.coursecampus.newCategoriesFormatted = '';
    if (data.coursecampus.newcategories?.length) {
      data.coursecampus.newcategories.sort(
        (
          a: { new_category: { name: string } },
          b: { new_category: { name: string } }
        ) => {
          if (a.new_category.name > b.new_category.name) {
            return 1;
          }
          if (b.new_category.name > a.new_category.name) {
            return -1;
          }
          return 0;
        }
      );

      data.coursecampus.newcategories.forEach(
        (element: { new_category: { name: any }; children: any[] }) => {
          if (data.coursecampus.newCategoriesFormatted !== '') {
            data.coursecampus.newCategoriesFormatted += ` | ${element.new_category.name}`;
          } else {
            data.coursecampus.newCategoriesFormatted +=
              element.new_category.name;
          }
          if (element.children) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            element.children?.length
              ? element.children.sort(function sortByName(
                  a: { name: string },
                  b: { name: string }
                ) {
                  if (a.name > b.name) {
                    return 1;
                  }
                  if (b.name > a.name) {
                    return -1;
                  }
                  return 0;
                })
              : null;

            element.children.forEach(elementChild => {
              data.coursecampus.newCategoriesFormatted += ` | ${elementChild.name}`;
            });
          }
        }
      );
    }

    // Declare prices
    if (data.coursecampus.totalThisCourse && data.coursecampus.price) {
      onlyCourse = formatValue(
        data.coursecampus.totalThisCourse.onlyCourse,
        data.coursecampus.campus.currency_code
      );
      onlyCourseWithoutSR =
        data.coursecampus.totalThisCourse.onlyCourseWithoutSR;
      onlyCourseWithoutSRFormatted = formatValue(
        data.coursecampus.totalThisCourse.onlyCourseWithoutSR,
        data.coursecampus.campus.currency_code
      );

      discountApplied = data.coursecampus.totalThisCourse.applied;
      oldOnlyCourse = data.coursecampus.totalThisCourse.oldValue
        ? formatValue(
            data.coursecampus.totalThisCourse.oldValue,
            data.coursecampus.campus.currency_code
          )
        : null;
      priceValidity = data.coursecampus.price.date;
    } else if (data.coursecampus.priceError) {
      onlyCourse = formatValue(0, data.coursecampus.campus.currency_code);
      onlyCourseWithoutSR = 0;
      onlyCourseWithoutSRFormatted = formatValue(
        0,
        data.coursecampus.campus.currency_code
      );

      discountApplied = null;
      oldOnlyCourse = null;
      priceValidity = {
        startDate: data.coursecampus.startDate,
        endDate: data.coursecampus.dueDate,
      };

      // data.itemDemand = api.get('/demand', {
      //   params: {
      //     fromQuote: true,
      //     school_id: data.coursecampus.campus.school_id,
      //   },
      // });

      // const itemDemand = useCallback(async () => {
      //   data.itemDemand = await api.get('/demand', {
      //     params: {
      //       fromQuote: true,
      //       school_id: data.coursecampus.campus.school_id,
      //     },
      //   });
      // }, [data]);
    }
    data.itemDemand = data.coursecampus.campus.school.demanddoing;
    onshore = data.coursecampus.course.onshore === 1 ? true : false;
    renewal =
      typeof data.coursecampus.course.renewal !== 'undefined' &&
      (data.coursecampus.course.renewal === 1 ||
        (data.coursecampus.course.renewal === 2 && onlyRenewal))
        ? true
        : false;
    online = data.coursecampus.campus.type === 2 ? true : false;
  }

  // if (isAccommodation) {
  //   console.log('Entrou no isAccommodation');
  //   if ((data.ageFrom || data.ageTo) && quotesData.student.age === -1) {
  //     data.showPriceByAge = true;
  //     // data.showPriceByAge = false;
  //     if (isIEAccount && useStudentAge) {
  //       data.showPriceByAge = false;
  //       data.unavailableAge = true;
  //     }
  //   }
  //   if ((data.ageFrom || data.ageTo) && quotesData.student.age !== -1) {
  //     if (data.ageFrom && data.ageTo) {
  //       if (
  //         !(
  //           data.ageFrom <= quotesData.student.age &&
  //           data.ageTo >= quotesData.student.age
  //         )
  //       ) {
  //         data.showPriceByAge = true;
  //         data.unavailableAge = false;
  //         if (isIEAccount && useStudentAge) {
  //           data.showPriceByAge = false;
  //           data.unavailableAge = true;
  //         }
  //       }
  //     } else if (data.ageFrom && !data.ageTo) {
  //       if (!(data.ageFrom <= quotesData.student.age)) {
  //         data.showPriceByAge = true;
  //         data.unavailableAge = false;
  //         if (isIEAccount && useStudentAge) {
  //           data.showPriceByAge = false;
  //           data.unavailableAge = true;
  //         }
  //       }
  //     } else if (!data.ageFrom && data.ageTo) {
  //       if (!(data.ageTo >= quotesData.student.age)) {
  //         data.showPriceByAge = true;
  //         data.unavailableAge = false;
  //         if (isIEAccount && useStudentAge) {
  //           data.showPriceByAge = false;
  //           data.unavailableAge = true;
  //         }
  //       }
  //     }
  //   }
  //   if (
  //     loadedRealValues &&
  //     (data.price.srmaterial ||
  //       data.price.srenrol ||
  //       data.price.srcourse ||
  //       data.price.srperiod)
  //   ) {
  //     accommodationHasSpecialRates = true;
  //   }
  //   onlyAccommodation = data.totalThisAccommodation
  //     ? formatValue(
  //         data.totalThisAccommodation?.onlyAccommodation,
  //         data.currency_code
  //       )
  //     : null;
  //   discountAppliedAccommodation = data.totalThisAccommodation?.applied
  //     ? data.totalThisAccommodation?.applied
  //     : null;

  //   onlyAccommodationWithoutSR = data.totalThisAccommodation
  //     ? formatValue(
  //         data.totalThisAccommodation?.onlyAccommodationWithoutSR,
  //         data.currency_code
  //       )
  //     : null;
  //   price = data.price || '';
  //   priceOld = data.priceOld || '';
  //   partnerName = data.partner;
  //   campusName = data.campus?.name ? data.campus?.name : '';
  //   schoolName = data.campus?.school?.name ? data.campus?.school?.name : '';
  //   duration = data.duration;
  //   flagImg = `${process.env.REACT_APP_NG_URL}/images/flags/${data.accommodation.city?.country?.flagName}.png`;
  //   countryName = data.accommodation.city?.country?.name;
  //   cityName = data.accommodation.city?.name;
  //   itemName = `${data.name} - ${data.type} Room - ${data.accommodation.bathroom} Bathroom - ${data.regime}`;

  //   if (data.totalThisAccommodation && data.price) {
  //     onlyCourse = formatValue(
  //       data.totalThisAccommodation?.onlyAccommodation,
  //       data.currency_code
  //     );
  //     oldOnlyCourse = data.totalThisAccommodation.oldValue
  //       ? formatValue(data.totalThisAccommodation.oldValue, data.currency_code)
  //       : undefined;
  //     placementFeeFormatted = formatValue(
  //       data.totalThisAccommodation.onlyEnrol,
  //       data.currency_code
  //     );
  //     priceValidity = data.price.date;
  //     console.log('Passou no isAccommodation');
  //   }
  // } else if (isAccommodation && data.accommodation.priceError) {
  //   onlyCourse = formatValue(0, data.accommodation.campus.currency_code);
  //   onlyAccommodation = formatValue(0, data.accommodation.campus.currency_code);
  //   onlyCourseWithoutSR = 0;
  //   onlyCourseWithoutSRFormatted = formatValue(
  //     0,
  //     data.accommodation.campus.currency_code
  //   );

  //   discountApplied = null;
  //   oldOnlyCourse = null;
  //   priceValidity = {
  //     startDate: data.accommodation.startDate,
  //     endDate: data.accommodation.dueDate,
  //   };
  // }

  if (isInsurance || isAddons) {
    price = data.price || '';
    priceOld = data.priceOld || '';
    partnerName = data.partner ? data.partner : '';
    duration = data.duration;
    itemName = data.name;
    campusName = data.campus?.name ? data.campus?.name : '';
    schoolName = data.campus?.school?.name ? data.campus?.school?.name : '';
    if (schoolName === '' && data.campus?.campus?.school?.name) {
      schoolName = data.campus?.campus?.school?.name;
    }
    categories = data.category ? data.category.name : undefined;

    if (data.totalThisFee !== undefined && data.price) {
      onlyCourse = formatValue(data.totalThisFee.value, data.currency_code);
      oldOnlyCourse = data.totalThisFee.oldValue
        ? formatValue(data.totalThisFee.oldValue, data.currency_code)
        : undefined;
      priceValidity = data.price.date;
    }
    if (!data.campus) {
      flagImg =
        data.country && data.country.nationality
          ? `${process.env.REACT_APP_NG_URL}/images/flags/${data.country.nationality?.flag}`
          : undefined;
      countryName = data.country ? data.country.name : undefined;
      cityName = data.city ? data.city.name : undefined;
      if (!countryName && data.country?.label) {
        countryName = data.country?.label;
      }
      if (!cityName && data.city?.label) {
        cityName = data.city?.label;
      }
      if (!flagImg && countryName) {
        flagImg = `${
          process.env.REACT_APP_NG_URL
        }/images/flags/${countryName.replaceAll(' ', '-')}.png`;
      }
    } else if (data.campus.city?.country?.flagName) {
      flagImg = data.campus.city.country
        ? `${process.env.REACT_APP_NG_URL}/images/flags/${data.campus.city.country.flagName}.png`
        : undefined;
      countryName = data.campus.country
        ? data.campus.city.country.name
        : undefined;
      cityName = data.campus.city ? data.campus.city.name : undefined;
    } else if (data.campus.country?.nationality) {
      flagImg = data.campus.country
        ? `${process.env.REACT_APP_NG_URL}/images/flags/${data.campus.country.nationality.flag}`
        : undefined;
      countryName = data.campus.country ? data.campus.country.name : undefined;
      cityName = data.campus.city ? data.campus.city.name : undefined;
    }
  }

  if (isExperience) {
    const experienceCategories = [
      { id: 1, name: 'Sport' },
      { id: 2, name: 'Nature' },
      { id: 3, name: 'Social Impact' },
      { id: 4, name: 'Entertainment' },
      { id: 5, name: 'Food' },
      { id: 6, name: 'Art' },
      { id: 7, name: 'Study' },
      { id: 8, name: 'Leisure' },
    ];

    partnerName = data.partner.name;
    duration = data.duration;
    durationType = data.durationType;
    itemName = data.name;
    categories = experienceCategories.find(
      (category: ExperienceCategory) => category.id === data.categoryDic
    )?.name;
    departureDateOptions = data.dates.map((date: ExperienceDepartureDate) => ({
      value: date.id,
      label: `${format(parseISO(date.startDate), 'dd/MM/yyyy')} - ${format(
        parseISO(date.endDate),
        'dd/MM/yyyy'
      )}`,
    }));
    if (data.totalThisExperience && data.currency.code) {
      onlyCourse = formatValue(
        data.totalThisExperience.value,
        data.currency.code
      );
    }
    data.pricePeriod = data.price?.periodType;
  }

  const openViewItemPricelistModal = useCallback(() => {
    const message = {
      descriptionItem: data,
      descriptionItemId: data.id,
      descriptionItemType: type,
      fee: (isInsurance || isAddons) && data,
      experience: isExperience && data,
      accommodation: isAccommodation && data,
      action: 'open-view-item-pricelist-modal',
      origin: process.env.REACT_APP_URL as string,
    };

    angularPort?.postMessage(message);
  }, [
    data,
    type,
    isInsurance,
    isAddons,
    isExperience,
    isAccommodation,
    angularPort,
  ]);

  const openViewItemDescriptionModal = useCallback(() => {
    const message = {
      descriptionItemId: data.id,
      descriptionItemType: type,
      fee: (isInsurance || isAddons) && data,
      experience: isExperience && data,
      accommodation: isAccommodation && data,
      action: 'open-view-item-description-modal',
      origin: process.env.REACT_APP_URL as string,
    };

    angularPort?.postMessage(message);
  }, [
    data,
    type,
    isInsurance,
    isAddons,
    isExperience,
    isAccommodation,
    angularPort,
  ]);

  const openSpecialRatesAppliedModal = useCallback(() => {
    const message = {
      descriptionItemId: data.id,
      descriptionItemType: type,
      fee: (isInsurance || isAddons) && data,
      experience: isExperience && data,
      accommodation: isAccommodation && data,
      action: 'open-view-special-rates-applied-modal',
      origin: process.env.REACT_APP_URL as string,
      descriptionItem: data,
    };

    angularPort?.postMessage(message);
  }, [
    data,
    type,
    isInsurance,
    isAddons,
    isExperience,
    isAccommodation,
    angularPort,
  ]);

  const openInstructionsForAgentsModal = useCallback(
    (dataType: string) => {
      const message = {
        descriptionItem: data.coursecampus,
        descriptionItemType: dataType,
        action: 'open-instructions-for-agents-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [angularPort, data]
  );

  const openAccInstructionsForAgentsModal = useCallback(
    (dataType: string) => {
      const message = {
        descriptionItem: data,
        descriptionItemType: dataType,
        action: 'open-instructions-for-agents-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [angularPort, data]
  );

  const handleDepartureDateChange = useCallback(
    departureDate => {
      setSelectedDepartureDate(departureDate);
      const formData = formRef.current?.getData() as ListItemFormData;
      formData.experienceDepartureDate = departureDate.value;
      formData.departureDate = departureDate;
      handleDurationChange(formData, isSuggestive);
    },
    [handleDurationChange, isSuggestive]
  );

  const insertedAt = useMemo(() => {
    const quotesNames = new Set<string>();

    quotesData.quotes?.forEach((quote, idx) => {
      let fixType = type.toLowerCase();

      if (
        type === filtersOptions.FILTERS_ADDONS ||
        type === filtersOptions.FILTERS_INSURANCES
      ) {
        fixType = 'fees';
      }

      quote[fixType].forEach((item: any) => {
        if (type === filtersOptions.FILTERS_COURSES) {
          if (item.courseCampus_id === data.id) {
            quotesNames.add(quote.config?.name || `Option ${idx + 1}`);
          }
        } else if (item.id === data.id) {
          quotesNames.add(quote.config?.name || `Option ${idx + 1}`);
        }
      });
    });

    return [...quotesNames];
  }, [data, quotesData, type]);

  const verifySubmit = useCallback(
    formData => {
      try {
        // setIsSubmitDisabled(true);
        if (!price && !isExperience) return;

        const itemDuration = formRef.current?.getFieldValue('duration');
        if (
          (!isExperience &&
            price.end &&
            itemDuration > price.end &&
            !priceOld &&
            data) ||
          (!isExperience &&
            price.begin &&
            itemDuration < price.begin &&
            !priceOld)
        ) {
          if (isInsurance || isAddons) {
            if (!data.extraprice) {
              throw new Error(
                'Cannot add an item to quote with an invalid duration'
              );
            }
          } else if (isAccommodation) {
            if (!data.extraprice) {
              throw new Error(
                'Cannot add an item to quote with an invalid duration'
              );
            }
          } else if (data.coursecampus) {
            if (!data.coursecampus.extraprice) {
              throw new Error(
                'Cannot add an item to quote with an invalid duration'
              );
            }
          }
        }
        handleAddItemToQuote(formData);
      } catch (error: any) {
        addToast({
          title: 'Error',
          type: 'error',
          description:
            error?.message || 'An error has occurred, please try again.',
        });
      }
    },
    [
      price,
      isExperience,
      priceOld,
      data,
      handleAddItemToQuote,
      isInsurance,
      isAddons,
      isAccommodation,
      addToast,
    ]
  );
  // const changeBirthdate = useCallback(() => {
  //   const [startDate, setStartDate] = useState(new Date());
  // }, []);

  const triggerChange = useCallback(() => {
    handleDurationChange(
      formRef.current?.getData() as ListItemFormData,
      isSuggestive
    );
  }, [handleDurationChange, isSuggestive]);

  const validateDuration = useCallback(() => {
    const itemDuration = formRef.current?.getFieldValue('duration');
    // if (
    //   (price.end && itemDuration > price.end) ||
    //   (price.begin && itemDuration < price.begin)
    // ) {
    //   setIsSubmitDisabled(true);
    //   formRef.current?.setFieldError('duration', 'Invalid duration');
    //   return;
    // }

    formRef.current?.setFieldError('duration', '');
    setIsSubmitDisabled(false);
  }, []);

  const openModalForChangeAge = (): void => {
    setIsModalOpened(!isModalOpened);
  };

  let wasInserted = !!insertedAt.length;
  // for edition suggestive fees case
  if (useInternalFields && wasAdded) wasInserted = true;
  if (useInternalFields && !wasAdded) wasInserted = false;

  let campusId = null;
  if (type === 'Courses') {
    campusId = data.coursecampus.campus.id;
  } else if (data.campus) {
    campusId = data.campus.id;
  }

  const valueWasExpired =
    type === 'Courses'
      ? loadedRealValues &&
        priceValidity &&
        data.coursecampus.priceIsExpired &&
        !data.coursecampus.priceIsAboutToExpire &&
        !data.coursecampus.srIsAboutToExpire
      : loadedRealValues &&
        priceValidity &&
        data.priceIsExpired &&
        !data.priceIsAboutToExpire &&
        !data.srIsAboutToExpire;

  return (
    <Container
      wasInserted={wasInserted}
      priceError={isCourse && data.coursecampus.priceError}
      className={isCourse && data.coursecampus.priceError ? 'priceError' : ''}
    >
      {schoolLogo && (
        <img
          src={schoolLogo}
          alt={`${schoolName} ${isCourse ? data.coursecampus.popular : ''}`}
        />
      )}
      <div className="flags">
        {renewal && <span className="renewal">renewal</span>}
        {onshore && <span className="onshore">onshore</span>}
        {online && <span className="online">online</span>}
      </div>

      {isModalOpened && (
        <ModalChangeAge
          studentId={quotesData.student.id}
          fullname={quotesData.student.fullName}
          isModalOpened={isModalOpened}
          callbackUpdateStudent={updateStudent}
          setIsModalOpened={setIsModalOpened}
        />
      )}

      {isModalOpened && (
        <ModalChangeAge
          studentId={quotesData.student.id}
          fullname={quotesData.student.fullName}
          isModalOpened={isModalOpened}
          callbackUpdateStudent={updateStudent}
          setIsModalOpened={setIsModalOpened}
        />
      )}

      {isReportErrorModalOpened && (
        <ReportErrorModal
          isOpen={isReportErrorModalOpened}
          setIsReportErrorModalOpened={setIsReportErrorModalOpened}
          itemType={type}
          campusId={campusId}
          campusName={campusName}
          itemId={type === 'Courses' ? data.courseCampus_id : data.id}
          itemName={itemName}
        />
      )}

      {isAddedOptionsModalOpened && (
        <AddedOptionsModal
          isOpen={isAddedOptionsModalOpened}
          setIsAddedOptionsModalOpened={setIsAddedOptionsModalOpened}
          insertedAt={insertedAt}
        />
      )}

      <Form
        ref={formRef}
        initialData={{ duration }}
        onSubmit={formData => {
          verifySubmit(formData);
        }}
      >
        <Input
          type="hidden"
          name="id"
          id={`id_${data.id}`}
          value={data.id}
          containerStyle={{
            display: 'none',
          }}
        />

        <Input
          type="hidden"
          name="data"
          id={`data_${data.id}`}
          value={dataAsString}
          containerStyle={{
            display: 'none',
          }}
        />

        <Input
          type="hidden"
          name="type"
          id={`type_${data.id}`}
          value={type}
          containerStyle={{
            display: 'none',
          }}
        />

        <Content>
          {(isCourse || isAccommodation) && (
            <div className="list-item--campus">
              <img src={flagImg} alt={countryName} />
              {cityName && (
                <small style={{ marginTop: '2px' }}>{cityName}</small>
              )}
            </div>
          )}
          {(isAddons || isInsurance) && (
            <div className="list-item--campus">
              {flagImg && <img src={flagImg} alt={countryName} />}
              {cityName && <small>{cityName}</small>}
              {!cityName && countryName && <small>{countryName}</small>}
            </div>
          )}
          <div className="list-item--school">
            {campusName && schoolName && (
              <small>
                {`${campusName} - `}
                {isMySchool && '(My School) '}
                {schoolName}
              </small>
            )}
            {campusName && !schoolName && partnerName && (
              <small>
                {`${campusName} - `}
                {partnerName}
              </small>
            )}
            {!campusName && !schoolName && partnerName && (
              <small>{partnerName}</small>
            )}
          </div>
          <h3>
            {isCourse &&
              data.coursecampus.course.ageFrom &&
              data.coursecampus.course.ageTo && (
                <span className="limitAge" title="limit age">
                  {data.coursecampus.course.ageFrom}
                  {' to '}
                  {data.coursecampus.course.ageTo}
                </span>
              )}
            {isCourse &&
              data.coursecampus.course.ageFrom &&
              !data.coursecampus.course.ageTo && (
                <span className="limitAge" title="limit age">
                  {data.coursecampus.course.ageFrom}+
                </span>
              )}
            {isCourse &&
              !data.coursecampus.course.ageFrom &&
              data.coursecampus.course.ageTo && (
                <span className="limitAge" title="limit age">
                  {data.coursecampus.course.ageTo}-
                </span>
              )}
            {isAccommodation && data.ageFrom && data.ageTo && (
              <span className="limitAge" title="limit age">
                {data.ageFrom}
                {' to '}
                {data.ageTo}
              </span>
            )}
            {isAccommodation && data.ageFrom && !data.ageTo && (
              <span className="limitAge" title="limit age">
                {data.ageFrom}+
              </span>
            )}
            {isAccommodation && !data.ageFrom && data.ageTo && (
              <span className="limitAge" title="limit age">
                {data.ageTo}-
              </span>
            )}

            {itemName}
          </h3>
          <div className="list-item--info">
            {isCourse &&
              data.coursecampus.newCategoriesFormatted &&
              data.coursecampus.newCategoriesFormatted}
            {!isAccommodation && categories}
            {isAccommodation && (
              <span>
                Placement fee: <strong>{placementFeeFormatted}</strong>
              </span>
            )}
            {isExperience && <span>{` (${duration} ${durationType}s)`}</span>}
          </div>

          {isCourse && data.coursecampus.campus.school.demanddoing && (
            <span className="updating-label">Updating Values</span>
          )}

          {isExperience && (
            <div className="form-group">
              <label htmlFor="experienceDepartureDate">Departure date:</label>
              <Select
                options={departureDateOptions}
                value={departureDateOptions.find(
                  (option: ExperienceDepartureDateOption) =>
                    option.value === selectedDepartureDate?.value
                )}
                name="experienceDepartureDate"
                id="experienceDepartureDate"
                placeholder="Select departure date"
                onChange={handleDepartureDateChange}
              />
            </div>
          )}
        </Content>

        {isCourse && !data.coursecampus.priceError && data.showPriceByAge && (
          <Price loadedRealValues={loadedRealValues}>
            {discountApplied !== null && (
              <div className="discount-applied">
                <small>{discountApplied}</small>
                {onlyCourseWithoutSR && onlyCourseWithoutSR !== onlyCourse && (
                  <small className="value-total">
                    {onlyCourseWithoutSRFormatted}
                  </small>
                )}
              </div>
            )}
            {discountAppliedAccommodation !== null && (
              <div
                className="discount-applied"
                style={{ position: 'absolute', top: '7px' }}
              >
                <small>{discountAppliedAccommodation}</small>
                {onlyAccommodationWithoutSR &&
                  onlyAccommodationWithoutSR !== onlyAccommodation && (
                    <small className="value-total">
                      {onlyAccommodationWithoutSR}
                    </small>
                  )}
              </div>
            )}
            <h3
              className={
                (isCourse && data.coursecampus.priceIsExpired) ||
                (!isCourse && data.priceIsExpired)
                  ? 'redValue'
                  : ''
              }
            >
              {hasSpecialRates && (
                <Tooltip title="This course has special rates">
                  <IoIosPricetags
                    size={14}
                    style={{ marginRight: 4, marginBottom: '4px' }}
                  />
                </Tooltip>
              )}
              {/* Valor do curso */}
              {discountApplied !== null && isCourse && (
                <span
                  className="with-discount"
                  onClick={openSpecialRatesAppliedModal}
                >
                  {onlyCourse}
                </span>
              )}
              {discountApplied == null && isCourse && <span>{onlyCourse}</span>}
              {discountApplied == null && isInsurance && (
                <span>{onlyCourse}</span>
              )}
              {discountApplied == null && isAddons && <span>{onlyCourse}</span>}
              {oldOnlyCourse && (
                <>
                  <small>{oldOnlyCourse}</small>
                  <MdLocalOffer size={12} />
                </>
              )}
              {accommodationHasSpecialRates && (
                <Tooltip title="This accommodation has special rates">
                  <IoIosPricetags
                    size={14}
                    style={{ marginRight: 4, marginBottom: '4px' }}
                  />
                </Tooltip>
              )}
              {discountAppliedAccommodation !== null && isAccommodation && (
                <span
                  className="with-discount"
                  onClick={openSpecialRatesAppliedModal}
                >
                  {onlyAccommodation}
                </span>
              )}
              {discountAppliedAccommodation == null && isAccommodation && (
                <span>{onlyAccommodation}</span>
              )}{' '}
            </h3>

            {loadedRealValues &&
              priceValidity &&
              !data.coursecampus.priceIsExpired &&
              !data.coursecampus.priceIsAboutToExpire &&
              !data.coursecampus.srIsAboutToExpire && (
                <div style={{ fontSize: 12 }}>
                  Valid until:{' '}
                  {format(parseISO(priceValidity.endDate), 'dd/MM/yyyy')}
                  {data.coursecampus.priceError}
                </div>
              )}

            {valueWasExpired && (
              <div>
                <div className="period-expired-alert">
                  <MdWarning size={13} />
                  <span style={{ marginLeft: '5px' }}>
                    Values expired at{' '}
                    {format(parseISO(priceValidity.endDate), 'dd/MM/yyyy')}
                  </span>
                </div>
              </div>
            )}

            {data.coursecampus.priceIsAboutToExpire &&
              data.coursecampus.srIsAboutToExpire && (
                <Tooltip
                  html
                  className="max-250-width text-center"
                  title={`<span>Check if your agency requested<br>
                  this school's values update<br><br>
                  values expire at: ${format(
                    parseISO(data.coursecampus.dueDate),
                    'dd/MM/yyyy'
                  )}
                  <br>
                  promotion expire at: ${format(
                    parseISO(data.coursecampus.srExpireAt),
                    'dd/MM/yyyy'
                  )}
                  </span>
                `}
                >
                  <div className="period-expiring-alert">
                    <MdWarning size={13} />
                    <span>This value is about to expire</span>
                  </div>
                </Tooltip>
              )}

            {data.coursecampus.priceIsAboutToExpire &&
              !data.coursecampus.srIsAboutToExpire && (
                <Tooltip
                  html
                  className="max-250-width text-center"
                  title={`<span>Check if your agency requested<br>
                  this school's values update<br><br>
                  values expire at: ${format(
                    parseISO(data.coursecampus.dueDate),
                    'dd/MM/yyyy'
                  )}
                  </span>
                `}
                >
                  <div className="period-expiring-alert">
                    <MdWarning size={13} />
                    <span>This value is about to expire</span>
                  </div>
                </Tooltip>
              )}

            {!data.coursecampus.priceIsAboutToExpire &&
              data.coursecampus.srIsAboutToExpire && (
                <Tooltip
                  html
                  className="max-250-width text-center"
                  title={`<span>Check if your agency requested<br>
                  this school's values update<br><br>
                  promotion expire at: ${format(
                    parseISO(data.coursecampus.srExpireAt),
                    'dd/MM/yyyy'
                  )}
                  </span>
                `}
                >
                  <div className="period-expiring-alert">
                    <MdWarning size={13} />
                    <span>This value is about to expire</span>
                  </div>
                </Tooltip>
              )}

            {data.coursecampus.priceError &&
              data.coursecampus.priceError === 'enrol' && (
                <div className="quote-config-alert">
                  <MdWarning size={13} />
                  <span>Unavailable item. Expired Values</span>
                </div>
              )}
            {data.coursecampus.priceError &&
              data.coursecampus.priceError === 'nationality' && (
                <div className="quote-config-alert">
                  <MdBlock size={13} />
                  <span>Unavailable price</span>
                </div>
              )}
            {data.coursecampus.priceError &&
              data.coursecampus.priceError === 'error' && (
                <div className="quote-config-alert">
                  <MdBlock size={13} />
                  <span>Unavailable price</span>
                </div>
              )}

            {!isCourse && data.priceError && data.priceError === 'enrol' && (
              <div className="quote-config-alert">
                <MdWarning size={13} />
                <span>Unavailable item. Expired Values</span>
              </div>
            )}
            {!isCourse && data.priceError && data.priceError === 'nationality' && (
              <div className="quote-config-alert">
                <MdBlock size={13} />
                <span>Unavailable price</span>
              </div>
            )}
            {!isCourse && data.priceError && data.priceError === 'error' && (
              <div className="quote-config-alert">
                <MdBlock size={13} />
                <span>Unavailable price</span>
              </div>
            )}
            {/* Change value by duration */}
            {isCourse &&
              data.pricePeriod !== 'fixed' &&
              !data.coursecampus.priceError && (
                <Duration key={`duration_${data.id}`}>
                  <Input
                    // defaultValue={data.coursecampus.duration}
                    type="number"
                    name="duration"
                    id={`duration_${data.id}`}
                    onChange={() => {
                      validateDuration();
                      clearTimeout(wait_timer);
                      wait_timer = setTimeout(triggerChange, WAIT_INTERVAL);
                    }}
                  />
                  {data.pricePeriod}(s)
                </Duration>
              )}

            {!isCourse && data.pricePeriod !== 'fixed' && (
              <Duration key={`duration_${data.id}`}>
                <Input
                  type="number"
                  name="duration"
                  id={`bduration_${data.id}`}
                  onChange={() => {
                    validateDuration();
                    clearTimeout(wait_timer);
                    wait_timer = setTimeout(triggerChange, WAIT_INTERVAL);
                  }}
                />
                {data.pricePeriod}(s)
              </Duration>
            )}
          </Price>
        )}

        {isCourse && !data.coursecampus.priceError && !data.showPriceByAge && (
          <Price loadedRealValues={loadedRealValues}>
            {(data.coursecampus.course.ageFrom ||
              data.coursecampus.course.ageTo) &&
              quotesData.student.age === -1 && (
                <div className="quote-config-alert">
                  <IoMdCalendar className="top-minus-2" size={13} />
                  <span>
                    Missing student birthdate,
                    <button
                      style={{
                        width: 'auto',
                      }}
                      type="button"
                      className="btn-link btn-no-style"
                      onClick={() => openModalForChangeAge()}
                    >
                      click here
                    </button>
                    to add it.
                  </span>
                </div>
              )}
            {(data.coursecampus.course.ageFrom ||
              data.coursecampus.course.ageTo) &&
              quotesData.student.age !== -1 &&
              data.unavailableAge && (
                <div className="quote-config-alert">
                  <IoMdCalendar size={13} />
                  <span>Unavailable item for the student age</span>
                </div>
              )}
          </Price>
        )}

        {isCourse && data.coursecampus.priceError && (
          <Price loadedRealValues={loadedRealValues}>
            {data.coursecampus.priceError &&
              data.coursecampus.priceError === 'enrol' && (
                <div className="quote-config-alert">
                  <MdWarning size={13} />
                  <span>Unavailable item. Expired Values</span>
                </div>
              )}
            {data.coursecampus.priceError &&
              data.coursecampus.priceError === 'nationality' && (
                <div className="quote-config-alert">
                  <MdBlock size={13} />
                  <span>Unavailable price</span>
                </div>
              )}
            {data.coursecampus.priceError &&
              data.coursecampus.priceError === 'duration' && (
                <div className="quote-config-alert">
                  <MdWarning size={13} />
                  <span>Error. Invalid Duration</span>
                </div>
              )}
            {data.coursecampus.priceError &&
              data.coursecampus.priceError === 'error' && (
                <div className="quote-config-alert">
                  <MdBlock size={13} />
                  <span>Unavailable price</span>
                </div>
              )}
            {data.pricePeriod !== 'fixed' && (
              <Duration key={`duration_${data.id}`}>
                <Input
                  type="number"
                  name="duration"
                  id={`errorduration_${data.id}`}
                  onChange={() => {
                    // validateDuration();
                    clearTimeout(wait_timer);
                    wait_timer = setTimeout(triggerChange, WAIT_INTERVAL);
                  }}
                />
                {data.pricePeriod}(s)
              </Duration>
            )}
          </Price>
        )}

        {!isCourse && data.priceError && (
          <Price loadedRealValues={loadedRealValues}>
            {data.priceError && data.priceError === 'enrol' && (
              <div className="quote-config-alert">
                <MdWarning size={13} />
                <span>Unavailable item. Expired Values</span>
              </div>
            )}
            {data.priceError && data.priceError === 'nationality' && (
              <div className="quote-config-alert">
                <MdBlock size={13} />
                <span>Unavailable price</span>
              </div>
            )}
            {data.priceError && data.priceError === 'error' && (
              <div className="quote-config-alert">
                <MdBlock size={13} />
                <span>Unavailable price</span>
              </div>
            )}
            {data.priceError && data.priceError === 'duration' && (
              <div className="quote-config-alert">
                <MdWarning size={13} />
                <span>Error. Invalid Duration</span>
              </div>
            )}
            {data.pricePeriod !== 'fixed' && (
              <Duration key={`duration_${data.id}`}>
                <Input
                  type="number"
                  name="duration"
                  id={`errorduration_${data.id}`}
                  onChange={() => {
                    // validateDuration();
                    clearTimeout(wait_timer);
                    wait_timer = setTimeout(triggerChange, WAIT_INTERVAL);
                  }}
                />
                {data.pricePeriod}(s)
              </Duration>
            )}
          </Price>
        )}

        {!isExperience && !isCourse && data.showPriceByAge && !data.priceError && (
          <Price loadedRealValues={loadedRealValues}>
            {discountApplied !== null && (
              <div className="discount-applied">
                <small>{discountApplied}</small>
                {onlyCourseWithoutSR && onlyCourseWithoutSR !== onlyCourse && (
                  <small className="value-total">
                    {onlyCourseWithoutSRFormatted}
                  </small>
                )}
              </div>
            )}
            {discountAppliedAccommodation !== null && (
              <div
                className="discount-applied"
                style={{ position: 'absolute', top: '7px' }}
              >
                <small>{discountAppliedAccommodation}</small>
                {onlyAccommodationWithoutSR &&
                  onlyAccommodationWithoutSR !== onlyAccommodation && (
                    <small className="value-total">
                      {onlyAccommodationWithoutSR}
                    </small>
                  )}
              </div>
            )}
            <h3
              className={
                (isCourse && data.coursecampus.priceIsExpired) ||
                (!isCourse && data.priceIsExpired)
                  ? 'redValue'
                  : ''
              }
            >
              {hasSpecialRates && (
                <Tooltip title="This course has special rates">
                  <IoIosPricetags size={14} style={{ marginRight: 4 }} />
                </Tooltip>
              )}
              {discountApplied !== null && isCourse && (
                <span
                  className="with-discount"
                  onClick={openSpecialRatesAppliedModal}
                >
                  {onlyCourse}
                </span>
              )}
              {discountApplied == null && isCourse && <span>{onlyCourse}</span>}
              {discountApplied == null && isInsurance && (
                <span>{onlyCourse}</span>
              )}
              {discountApplied == null && isAddons && <span>{onlyCourse}</span>}
              {oldOnlyCourse && (
                <>
                  <small>{oldOnlyCourse}</small>
                  <MdLocalOffer size={12} />
                </>
              )}
              {accommodationHasSpecialRates && (
                <Tooltip title="This accommodation has special rates">
                  <IoIosPricetags size={14} style={{ marginRight: 4 }} />
                </Tooltip>
              )}
              {discountAppliedAccommodation !== null && isAccommodation && (
                <span
                  className="with-discount"
                  onClick={openSpecialRatesAppliedModal}
                >
                  {onlyAccommodation}
                </span>
              )}
              {discountAppliedAccommodation == null && isAccommodation && (
                <span>{onlyAccommodation}</span>
              )}
            </h3>
            {!data.priceIsAboutToExpire &&
              !data.srIsAboutToExpire &&
              !data.priceError &&
              !data.priceIsExpired && (
                <div style={{ fontSize: 12 }}>
                  Valid until:{' '}
                  {priceValidity?.endDate
                    ? format(parseISO(priceValidity.endDate), 'dd/MM/yyyy')
                    : ''}
                </div>
              )}
            {!data.priceIsAboutToExpire &&
              !data.srIsAboutToExpire &&
              data.priceIsExpired && (
                <div>
                  <div className="period-expired-alert">
                    <MdWarning size={15} />
                    <span style={{ marginLeft: '5px' }}>
                      Values expired at{' '}
                      {format(parseISO(priceValidity.endDate), 'dd/MM/yyyy')}
                    </span>
                  </div>
                </div>
              )}

            {data.priceIsAboutToExpire && data.srIsAboutToExpire && (
              <Tooltip
                html
                className="max-250-width text-center"
                title={`<span>Check if your agency requested<br>
                  this school's values update<br><br>
                  values expire at: ${format(
                    parseISO(data.price.date.endDate),
                    'dd/MM/yyyy'
                  )}
                  <br>
                  promotion expire at: ${format(
                    parseISO(data.srExpireAt),
                    'dd/MM/yyyy'
                  )}
                  </span>
                `}
              >
                <div className="period-expiring-alert">
                  <MdWarning size={13} />
                  <span>This value is about to expire</span>
                </div>
              </Tooltip>
            )}

            {data.priceIsAboutToExpire && !data.srIsAboutToExpire && (
              <Tooltip
                html
                className="max-250-width text-center"
                title={`<span>Check if your agency requested<br>
                  this school's values update<br><br>
                  values expire at: ${format(
                    parseISO(data.price.date.endDate),
                    'dd/MM/yyyy'
                  )}
                  </span>
                `}
              >
                <div className="period-expiring-alert">
                  <MdWarning size={13} />
                  <span>This value is about to expire</span>
                </div>
              </Tooltip>
            )}

            {!data.priceIsAboutToExpire && data.srIsAboutToExpire && (
              <Tooltip
                html
                className="max-250-width text-center"
                title={`<span>Check if your agency requested<br>
                  this school's values update<br><br>
                  promotion expire at: ${format(
                    parseISO(data.srExpireAt),
                    'dd/MM/yyyy'
                  )}
                  </span>
                `}
              >
                <div className="period-expiring-alert">
                  <MdWarning size={13} />
                  <span>This value is about to expire</span>
                </div>
              </Tooltip>
            )}
            {data.pricePeriod !== 'fixed' && (
              <Duration key={`duration_${data.id}`}>
                <Input
                  type="number"
                  name="duration"
                  id={`cduration_${data.id}`}
                  onChange={() => {
                    validateDuration();
                    clearTimeout(wait_timer);
                    wait_timer = setTimeout(triggerChange, WAIT_INTERVAL);
                  }}
                />
                {data.pricePeriod}(s)
              </Duration>
            )}
          </Price>
        )}

        {!isExperience && !isCourse && !data.showPriceByAge && (
          <Price loadedRealValues={loadedRealValues}>
            {(data.ageFrom || data.ageTo) && quotesData.student.age === -1 && (
              <div className="quote-config-alert">
                <IoMdCalendar className="top-minus-2" size={13} />
                <span>
                  Missing student birthdate,
                  <button
                    style={{
                      width: 'auto',
                    }}
                    type="button"
                    className="btn-link btn-no-style"
                    onClick={() => openModalForChangeAge()}
                  >
                    click here
                  </button>
                  to add it.
                </span>
              </div>
            )}
            {(data.ageFrom || data.ageTo) &&
              quotesData.student.age !== -1 &&
              data.unavailableAge && (
                <div className="quote-config-alert">
                  <IoMdCalendar size={13} />
                  <span>Unavailable item for the student age</span>
                </div>
              )}
          </Price>
        )}

        {/* {!isExperience && !isCourse && !data.showPriceByAge && (
          <Price loadedRealValues={loadedRealValues}>
            {(data.ageFrom || data.ageTo) && quotesData.student.age === -1 && (
              <div className="quote-config-alert">
                <IoMdCalendar size={13} />
                <span>Missing student age</span>
              </div>
            )}
            {(data.ageFrom || data.ageTo) &&
              quotesData.student.age !== -1 &&
              data.unavailableAge && (
                <div className="quote-config-alert">
                  <IoMdCalendar size={13} />
                  <span>Unavailable item for the student age</span>
                </div>
              )}
          </Price>
        )} */}

        {isExperience && data.price && (
          <Price loadedRealValues={loadedRealValues}>
            {!selectedDepartureDate?.value && (
              <div>
                You should select a departure date
                <br />
                to see this experience value :&#41;
              </div>
            )}

            {selectedDepartureDate.value && !onlyCourse && (
              <div>
                There is no price for this
                <br />
                experience with given departure date.
              </div>
            )}

            {selectedDepartureDate.value && (
              <>
                <h3
                  className={
                    (isCourse && data.coursecampus.priceIsExpired) ||
                    (!isCourse && data.priceIsExpired)
                      ? 'redValue'
                      : ''
                  }
                >
                  <span>{onlyCourse}</span>
                  {oldOnlyCourse && (
                    <>
                      <small>{oldOnlyCourse}</small>
                      <MdLocalOffer size={12} />
                    </>
                  )}
                  {data.pricePeriod === 'fixed' && <span>&nbsp;/fixed</span>}
                </h3>

                {data.pricePeriod !== 'fixed' && (
                  <Duration key={`duration_${data.id}`}>
                    <Input
                      type="number"
                      name="duration"
                      id={`dduration_${data.id}`}
                      onChange={() => {
                        handleDurationChange(
                          formRef.current?.getData() as ListItemFormData,
                          isSuggestive
                        );
                      }}
                    />
                    {data.pricePeriod}(s)
                  </Duration>
                )}
              </>
            )}
          </Price>
        )}

        {isExperience && selectedDepartureDate?.value && !data.price && (
          <Price loadedRealValues={loadedRealValues}>
            <div>There is no price for this experience.</div>
          </Price>
        )}

        {isExperience && !selectedDepartureDate?.value && (
          <Price loadedRealValues={loadedRealValues}>
            <div>
              You should select a departure date
              <br />
              to see this experience value :&#41;
            </div>
          </Price>
        )}

        <Actions>
          {data.validated && (
            <Tooltip
              title={format(parseISO(data.validated), 'dd/MM/yyyy HH:mm')}
            >
              <Actions.Validated>
                <FaThumbsUp size={12} />
                Agency Validation
              </Actions.Validated>
            </Tooltip>
          )}
          {isPremium && (
            <PartnerSchool>
              <MdStar size={12} />
              Partner
            </PartnerSchool>
          )}

          {isCourse && data.coursecampus?.campus?.seasons?.length > 0 && (
            <Tooltip
              html
              className="max-350-width"
              title={`<span style="color:yellow">Do not forget to add high season supplement for the dates listed below:</span>
            ${data.coursecampus.campus.seasons.map(
              (season: { name: any; startDate: any; endDate: any }) =>
                `<span>
                  <br />
                  <span>${season.name} : ${format(
                  parseISO(season.startDate),
                  'dd/MM/yyyy'
                )} to ${format(parseISO(season.endDate), 'dd/MM/yyyy')}</span>
                </span>`
            )}`}
            >
              <div className="icon-seasons">
                <MdToday size={12} />
                <span className="ml-5">Seasons</span>
              </div>
            </Tooltip>
          )}
          {(isAccommodation || isAddons || isInsurance) &&
            data.campus &&
            data.campus?.seasons &&
            data.campus?.seasons?.length > 0 && (
              <Tooltip
                html
                className="max-350-width"
                title={`<span style="color:yellow">Do not forget to add high season supplement for the dates listed below:</span>
                ${data.campus.seasons.map(
                  (season: { name: any; startDate: any; endDate: any }) =>
                    `<span>
                      <br />
                      <span>${season.name} : ${format(
                      parseISO(season.startDate),
                      'dd/MM/yyyy'
                    )} to ${format(
                      parseISO(season.endDate),
                      'dd/MM/yyyy'
                    )}</span>
                    </span>`
                )}`}
              >
                <div className="icon-seasons">
                  <MdToday size={12} />
                  <span className="ml-5">Seasons</span>
                </div>
              </Tooltip>
            )}

          <ActionsButtons
            openViewItemDescriptionModal={openViewItemDescriptionModal}
            openViewItemPricelistModal={openViewItemPricelistModal}
            loadedRealValues={loadedRealValues}
          >
            {((type !== 'Courses' && data.campus) || isCourse) && (
              <Dropdown>
                <Dropdown.Toggle
                  className="btn-white btn-outline btn-outline--gray"
                  id="dropdown-basic"
                >
                  <FaChevronDown size={13} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={openViewItemPricelistModal}>
                    Pricelists
                  </Dropdown.Item>
                  {type === 'Courses' &&
                    data.coursecampus?.campus?.school?.allySite && (
                      <Dropdown.Item
                        href={data.coursecampus.campus?.school?.allySite}
                        target="_blank"
                      >
                        School information
                      </Dropdown.Item>
                    )}
                  <Dropdown.Item
                    onClick={() => setIsReportErrorModalOpened(true)}
                  >
                    Report Error
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {type === 'Courses' && data.description && (
              <Tooltip title="Instructions for agents">
                <Button
                  type="button"
                  className="btn-outline btn-outline--gray"
                  onClick={() => openInstructionsForAgentsModal('course')}
                  style={{ padding: 0 }}
                >
                  <FcIdea
                    size={14}
                    style={{ margin: '9px 12px', height: '19px' }}
                  />
                </Button>
              </Tooltip>
            )}
            {type === 'Accommodations' && data.instruction && (
              <Tooltip title="Instructions for agents">
                <Button
                  type="button"
                  className="btn-outline btn-outline--gray"
                  onClick={() =>
                    openAccInstructionsForAgentsModal('accommodation')
                  }
                  style={{ padding: 0 }}
                >
                  <FcIdea
                    size={14}
                    style={{ margin: '9px 12px', height: '19px' }}
                  />
                </Button>
              </Tooltip>
            )}
            {/* {((type !== 'Courses' && data.campus) || type === 'Courses') && (
              <Tooltip title="Pricelists">
                <Button
                  type="button"
                  className="btn-outline btn-outline--gray"
                  style={{
                    padding: '5px',
                    width: '39px',
                    height: '39px',
                  }}
                  onClick={openViewItemPricelistModal}
                >
                  <IoFileTrayFull style={{ width: '20px', height: '20px' }} />
                </Button>
              </Tooltip>
            )} */}
            <Tooltip title="Details">
              <Button
                style={{
                  padding: '5px',
                  width: '39px',
                  height: '39px',
                }}
                type="button"
                className="btn-white btn-outline btn-outline--gray"
                onClick={openViewItemDescriptionModal}
              >
                <FaInfo />
              </Button>
            </Tooltip>
            {!isExperience &&
              type !== 'Courses' &&
              data.showPriceByAge &&
              !data.priceError && (
                <Button
                  type="submit"
                  className="btn-green"
                  loading={isLoading}
                  disabled={isSubmitDisabled || isLoading}
                  style={{
                    padding: '5px',
                    width: '39px',
                    height: '39px',
                  }}
                >
                  <FaPlus />
                </Button>
              )}
            {type === 'Courses' &&
              !data.coursecampus.priceError &&
              data.showPriceByAge && (
                <Button
                  type="submit"
                  className="btn-green"
                  disabled={isSubmitDisabled}
                  style={{
                    padding: '5px',
                    width: '39px',
                    height: '39px',
                  }}
                >
                  <FaPlus />
                </Button>
              )}
            {isExperience && selectedDepartureDate?.value && data.price && (
              <Button
                type="submit"
                className="btn-green"
                style={{
                  padding: '5px',
                  width: '39px',
                  height: '39px',
                }}
              >
                <FaPlus />
              </Button>
            )}
          </ActionsButtons>
          {insertedAt.length > 0 && (
            <Button
              style={{
                marginTop: '5px',
                display: 'flex',
                alignItems: 'center',
                background: 'none',
                color: 'var(--gray)',
                border: 'none',
                fontWeight: '400',
                paddingTop: '0px',
              }}
              onClick={() => setIsAddedOptionsModalOpened(true)}
            >
              <TiInputChecked size={18} color="var(--green)" />
              {insertedAt.length === 1
                ? 'added in 1 option'
                : `added in ${insertedAt.length} options`}
            </Button>
          )}

          {isAvailableWithAllyPlus && Boolean(showAllyPlusItems) && (
            <AllyPlusBox>
              <p>available with</p>
              <img src={allyPlus} alt="Selo Ally+" />
            </AllyPlusBox>
          )}
        </Actions>
      </Form>
    </Container>
  );
};

export default ListItem;
