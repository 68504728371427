import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Props as AsyncProps } from 'react-select/async';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import { FiAlertTriangle } from 'react-icons/fi';

import { useField } from '@unform/core';

import ReactTooltip from 'react-tooltip';
import { Container, ReactSelect, ASelect } from './styles';
import Tooltip from '../Tooltip';

interface Props extends SelectProps<OptionTypeBase, boolean> {
  name: string;
  register?: boolean;
  containerStyle?: object;
}

const Select: React.FC<Props> = ({
  name,
  register = true,
  containerStyle = {},
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.value);
  }, []);

  useEffect(() => {
    if (!register) return;

    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, register, registerField, rest.isMulti]);

  return (
    <>
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        data-testid="input-container"
      >
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        />

        {error && (
          <Tooltip title={error}>
            <FiAlertTriangle color="#c53030" size={20} />
          </Tooltip>
        )}
      </Container>
    </>
  );
};

export default Select;

interface AsyncSelectProps extends AsyncProps<OptionTypeBase, boolean> {
  name: string;
  containerStyle?: object;
}

export const AsyncSelect: React.FC<AsyncSelectProps> = ({
  name,
  containerStyle = {},
  ...rest
}) => {
  const selectRef = useRef(null);
  const [isFilled, setIsFilled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value
          );
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
      setValue: (ref: any, value: any) => {
        ref.select.state.value = value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      data-testid="input-container"
    >
      <ASelect
        cacheOptions
        ref={selectRef}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        defaultValue={defaultValue}
        classNamePrefix="react-select"
        {...rest}
      />
    </Container>
  );
};
